import { useToast } from "vue-toast-notification";

    const notify = useToast();
    
    const isOk = (message) => {
        notify.success((message ? message : 'Opération effectuée avec succès !'),{
             position: 'top-right'
        })
    }
    const isNotOk = (code) => {
        notify.error('Une erreur s\'est produite lors du traitement de l\'opération code erreur '+code+' !',{
             position: 'top-right'
        })
    }

export default {
    isOk,
    isNotOk
}


