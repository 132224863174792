import { ref } from "vue";
import { useRouter } from "vue-router"
import useHttp from '@/api'
import {useAuthStore} from '@/store'
import notify from '@/composition/notify'


export default function useAuth(){
    const store = useAuthStore()
    const router = useRouter()
    const  form = ref({
        email :null,
        password : null
    })
    const loading = ref(false)
    const errors = ref([]);

    const Handlelogin = async() =>{
        await router.push({name : 'login'});
    }
    const handleAuth = async() =>{
        loading.value = true
        try {
            const data = await useHttp.post('auth',form.value)
            store.setData(data)

            await router.push({name : 'home'});
            notify.isOk("Bienvenue "+store.user.name.toUpperCase()+' '+store.user.lastname)
        } catch (error) {
            errors.value = error.response.data.errors
            console.log(error.response.data.errors);
            
        }
        console.log(errors.value);
        
        loading.value = false
    }


    const handleLogout = async () =>{
        loading.value = true
        try {
            
            await useHttp.post('logout')
            
            
            await router.push({name:'login'})

            store.reset()
                
            notify.isOk('Vous êtes deconnecté !')
            
        } catch (error) {
            errors.value = error
        }
        loading.value = false
        
    }

    const handlePassword = async () => {
        
        await router.push({name:'password'})
    }
    return {
        handleAuth,
        handleLogout,
        handlePassword,
        Handlelogin,
        errors,
        form,
        loading
    }
}