import axios from "axios";
import { useAuthStore } from "@/store"; // Assure-toi que le chemin est correct

// Créer une instance Axios personnalisée
const useHttp = axios.create({
    baseURL: 'https://harmopharm.fr/api/',
    withCredentials: true,  // Envoie des cookies avec chaque requête
});

// Ajouter un intercepteur pour ajouter le token à chaque requête
useHttp.interceptors.request.use(config => {
    // Obtenir le token depuis le store Pinia
    const store = useAuthStore();
    const token = store.token;

    // Ajouter le token dans les en-têtes si disponible
    if (token) {
        config.headers['Authorization'] = `Bearer ${token}`;
    }
    
    return config;
}, error => {
    console.log('non ajouter');
    
    return Promise.reject(error);
});

export default useHttp;
