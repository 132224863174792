<template>
  <div class="bg-white cp-table">

      <div class="row d-flex justify-content-between mb-1">
          <div class="col-7">
              <p class="ml-3" v-show="props.data.length"><i class="nav-icon fas fa-database text-success"></i> {{ props.data.length }} produit(s)</p>
          </div>
          <div class="col-3">
               <!-- Zone de recherche -->
              <input v-model="searchQuery" placeholder="Rechercher..." class="form-control" />
          </div>
      </div>
    <!-- Tableau des données -->
      <div class="card-body table-responsive p-0" style="height: 65vh;">
          <table class="table table-striped table-hover text-nowrap">
              <thead class="bg-hp-green sticky-top top-0">
                  <tr>
                      <th>#</th>
                      <th scope="col">Marque</th>
                      <th scope="col">Désignation</th>
                      <th scope="col">Forme galénique</th>
                      <th scope="col">dosage par boite</th>
                      <th scope="col">Unité de mesure</th>
                      <th scope="col">EAN</th>
                      <th scope="col">Prix d'achat</th> 
                      <th scope="col">Prix de vente</th>
                      <th scope="col"></th>
                  </tr>
              </thead>
              <tbody>
                  <template v-if="paginatedData.length">
                      <tr v-for="(item, index) in paginatedData" :key="index">
                          <td>{{ countItem(index) }}</td>
                              <td>{{ item.marque }}</td>
                              <td>{{ item.forme_galenique }}</td>
                              <td>{{ item.designation }}</td>
                              <td>{{ item.dosage }}</td>
                              <td>{{ item.unite_de_mesure }}</td>
                              <td>{{ item.ean }}</td>
                              <td><template v-if="item.prix_achat">{{ item.prix_achat }} F CFA</template></td>
                              <td>
                                <template v-if="item.prix_de_vente">{{ item.prix_de_vente }} F CFA</template>
                              </td>
                              <td>
                                  <div class="btn-group btn-group-sm" role="group" aria-label="Small button group">
                                      <button type="button" class="btn btn-outline-primary" @click="handleShow(item)"><i class="nav-icon fas fa-edit"></i></button>
                                      <button type="button" class="btn btn-outline-danger" @click="handleDelete(item)" data-toggle="modal" data-target="#modal-sm"><i class="nav-icon fas fa-trash"></i></button>
                                  </div>
                              </td>
                      </tr>
                  </template>
                  <template v-else>
                  <tr>
                      <td colspan="10" class="text-center"><span class="text-center mt-5"><i class="nav-icon fas fa-exclamation-triangle text-warning mr-3"></i>Aucune information trouvée !</span></td>
                  </tr>
                      
                  </template>
              </tbody>
          </table>
      </div>
    <!-- Pagination -->
      <div class="d-flex flex-row-reverse">
          <nav aria-label="Page navigation example">
              <ul class="pagination justify-content-end">
          
                  <li :class="`page-item ${currentPage === 1 ? 'disabled' : ''}`" >
                      <a class="page-link" @click="previousPage" >Précédent</a>
                  </li>
                  <li class="page-item">
                      <a class="page-link" href="#">{{ currentPage }}</a>
                  </li>
                  <li class="page-item">
                      <a class="page-link" href="#">/ </a>
                  </li>
                  <li class="page-item">
                      <a class="page-link" href="#">{{ totalPages }}</a>
                  </li>
                  <li :class="`page-item ${currentPage === totalPages ? 'disabled' : ''}`">
                      <a class="page-link" @click="nextPage" >Suivant</a>
                  </li>
              </ul>
          
          </nav>
          <ul class="pagination justify-content-end mr-1"> 
          <p class="text-left mr-2 mt-2"> Afficher par nombre de </p>
              <select v-model="itemsPerPage" class="form-control" style="width: 5rem;">
                  <option :value="10">10 </option>
                  <option :value="25">25 </option>
                  <option :value="50">50 </option>
                  <option :value="100">100 </option>
                  <option :value="150">150 </option>
              </select>
          </ul>
  
      </div>
  </div>
  <ProductModal :data="updatedData"  />
  <DeleteModal :items="deletedData"/>
</template>

<script setup>
  import { ref, computed } from 'vue';
  import { Modal } from "bootstrap/dist/js/bootstrap.bundle";
  import ProductModal from '@/components/modals/ProductModal.vue';
  import DeleteModal from "@/components/delete/DeleteModal.vue"


    const searchQuery = ref('');
    const currentPage = ref(1);
    const itemsPerPage = ref(10);
      
    // Simule des données
    const props = defineProps({
      data:{
        type: Array,
        required:false
      }
    })
    // Données réactives pour la recherche et la pagination
 
    // Filtrer les données en fonction de la recherche
    const filteredData = computed(() => {
      let items = props.data
      if (searchQuery.value) {
        items = props.data.filter((item) =>
          item.marque != null && item.marque.toLowerCase().includes(searchQuery.value.toLowerCase()) ||
          item.forme_galenique != null && item.forme_galenique.toLowerCase().includes(searchQuery.value.toLowerCase()) ||
          item.dosage != null && item.dosage.toLowerCase().includes(searchQuery.value.toLowerCase()) ||
          item.designation != null && item.designation.toLowerCase().includes(searchQuery.value.toLowerCase()) ||
          item.unite_de_mesure != null && item.unite_de_mesure.toLowerCase().includes(searchQuery.value.toLowerCase()) ||
          item.prix_achat != null && item.prix_achat.toLowerCase().includes(searchQuery.value.toLowerCase()) ||
          item.prix_de_vente != null && item.prix_de_vente.toLowerCase().includes(searchQuery.value.toLowerCase()) ||
          item.ean != null && item.ean.toLowerCase().includes(searchQuery.value.toLowerCase()) 
        );
      }
      return items;
    });

    // Calculer les données paginées
    const paginatedData = computed(() => {
      const start = (currentPage.value - 1) * itemsPerPage.value;
      const end = start + itemsPerPage.value;
      return filteredData.value.slice(start, end);
    });

    // Nombre total de pages
    const totalPages = computed(() => {
      return Math.ceil(filteredData.value.length / itemsPerPage.value);
    });

    // Aller à la page précédente
    const previousPage = () => {
      if (currentPage.value > 1) {
        currentPage.value--;
      }
    };

    // Aller à la page suivante
    const nextPage = () => {
      if (currentPage.value < totalPages.value) {
        currentPage.value++;
      }
    };

   const countItem = (index) => (index + 1 + (currentPage.value - 1) * itemsPerPage.value)

    const updatedData=ref();

   const handleShow = (item) => {
      //     // Affecter les valeurs des données dans item au formulaire
      updatedData.value = item
          const modalElement = document.getElementById("modal-product");
    
            let modal = Modal.getInstance(modalElement); // Vérifier si une instance existe déjà
            if (!modal) {
              modal = new Modal(modalElement); // Si elle n'existe pas, la créer
            }
    
          modal.show();
        }


        const deletedData=ref();
   const handleDelete = (item) => {
      //     // Affecter les valeurs des données dans item au formulaire
      deletedData.value = {
          url: 'product',
          id : item.id,
          title : 'ce produit',
          name : item.designation
      }
          const modalElement = document.getElementById("modal-delete");
    
            let modal = Modal.getInstance(modalElement); // Vérifier si une instance existe déjà
            if (!modal) {
              modal = new Modal(modalElement); // Si elle n'existe pas, la créer
            }
    
          modal.show();
        }
</script>

<style scoped>
a{
  cursor: pointer;
}
.bg-hp-orange{
  background-color: #FBB03B !important;
  color:#fff
}
.bg-hp-green{
  background-color: #0D7D62 !important;
  color:#fff
}
.cp-table{
  border: 1px solid #d2d0d0b3;
  border-radius: 5px;
  padding: 3px;
}
</style>
