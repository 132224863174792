<template >
    <div class="hold-transition login-page">
        <div class="login-box">

            <div class="card card-outline card-success">
                <div class="card-header text-center">
                    <a href="#" class="h1"><b style="color: #FBB03B;">Harmopharm</b> <span style="color:#0D7D62">SAS</span></a>
                </div>
                <div class="card-body">
                    <p class="login-box-msg">Connectez-vous pour commencer</p>
                <form method="post">
                    <div class="input-group">
                        <input type="email" class="form-control" v-model="form.email" placeholder="Adresse email">
                        <div class="input-group-append">
                            <div class="input-group-text">
                                <span class="fas fa-envelope"></span>
                            </div>
                        </div>
                    </div>
                    <span class="text-danger ml-1" v-if="errors.email">{{ errors.email[0] }}</span>
                    <div class="input-group mt-3">
                        <input type="password" class="form-control"  v-model="form.password" placeholder="Mot de passe">
                        <div class="input-group-append">
                            <div class="input-group-text">
                                <span class="fas fa-lock"></span>
                            </div>
                        </div>
                    </div>
                    <span class="text-danger ml-1" v-if="errors.password">{{ errors.password[0] }}</span>
                    <div class="row text-center mt-2 mb-3 mt-3">
                    
                    <div class="col-12">
                        <button type="submit" class="btn btn-success btn-block" @click.prevent="handleAuth" :disabled="loading">
                        <div class="spinner-border spinner-border-sm" role="status" v-if="loading" ></div>
                       Se connecter
                    </button>
                    </div>
                    
                    </div>
                </form>
              

                <p class="mb-1">
                    <button class="btn btn-dark btn-block" @click.prevent="handlePassword">J'ai oublié mon mot de passe !</button>
                </p>
                
                </div>

            </div>

        </div>
    </div>
</template>

<script setup>
import useAuth from '@/composition/useAuth'
const { handleAuth, handlePassword, form, loading, errors } = useAuth()

</script>

<style lang="css" scoped>
.login-page{
    background-image: url('@/assets/img/comp.jpg');
    background-repeat: no-repeat;
    background-size: cover;
}

@media screen and (max-width:600px) {
    .login-page{
        background-image: url('@/assets/img/nature1.jpg');
        /* background-size: 100vh; */
    }
}
</style>
