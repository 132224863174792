<template>
    <div class="modal fade" id="modal-stock">
        <div class="modal-dialog modal-lg">
            <div class="modal-content">
                <div class="modal-header">
                    <h4 class="modal-title">Stock</h4>
                    <button type="button" class="close" data-dismiss="modal" aria-label="Close" @click="handleClose">
                        <span aria-hidden="true">&times;</span>
                    </button>
                </div>
                <form action="#">
                <input type="hidden" v-model="form.id">
                    <div class="modal-body">
                    <div class="row">
                        <div class="col-6">
                            <div class="form-group">
                                <label for="mq">Désignation</label>
                                <SelectVieW :options="product" @return-id="handleGetId" />
                            </div>
                        </div>
                        <div class="col-6">
                            <div class="form-group">
                                <label for="qt">Quantité</label>
                                <input type="text" v-model="form.quantite" class="form-control" id="qt" placeholder="Quantité actuelle">
                                 
                            </div>
                        </div>
                        
                    </div>
                    <div class="row">
                        <div class="col-6">
                            <div class="form-group">
                                <label for="sa">Stock alerte</label>
                                <input type="text" v-model="form.stock_alerte" class="form-control dateHp" id="sa" placeholder="Stock alerte">
                            </div>
                        </div>
                        <div class="col-6">
                            <div class="form-group">
                                <label for="sc">Stock critique</label>
                                <input type="text" v-model="form.stock_critique" class="form-control" id="sc" placeholder="Stock critique">
                                
                            </div>
                        </div>
                       
                        
                    </div>
                  
                    </div>
                <div class="modal-footer justify-content-between">
                    <button type="button" class="btn btn-default" data-dismiss="modal" id="close" @click="handleClose">fermer</button>
                    <button type="button" class="btn btn-success" @click.prevent="saveData" :disabled="loading">
                        <div class="spinner-border spinner-border-sm" role="status" v-if="loading" ></div>
                        Enregistrer <i class="fas fa-save nav-icon"></i>
                    </button>
                </div>
                </form>
            </div>
        </div>
    </div>       
</template>


<script setup>
import { onMounted, ref, watch } from 'vue';
import useStock from '../../composition/useStock';
import { Modal } from "bootstrap/dist/js/bootstrap.bundle";
import SelectVieW from '@/components/select/SelectStockView.vue'

    const { form, handleSave, loading, product,getProduct } = useStock()

    const emit = defineEmits(['update'])

    const saveData = async()=>{
        await handleSave()

        emit('update',true)
    }

    const props = defineProps({
        data:{
            type:Object,
            require:false
        }
    })


    form.value = { ...props.data };
    watch(() => props.data, (newData) => {
        form.value = { ...newData };
        
    });

    const handleGetId = (id) =>{
        form.value.produit = id
    }

    const formData = ref({
        id: 0,
        produit :null,
        entreprise :null,
        utilisateur :null,
        quantite :null,
        stock_critique :null,
        stock_alerte :null
    })

    onMounted(()=>{
        getProduct()
    })
    const handleClose = () => {
    const modalElement = document.getElementById("modal-stock");
    form.value = {...formData.value};
    // Si une instance du modal existe déjà
        const modal = Modal.getInstance(modalElement); // Récupérer l'instance du modal existante
        emit('update',false)
        if (modal) {
            modal.hide(); // Ferme le modal
        }
    }
   
</script>