import useHttp from "@/api"
import { ref } from "vue"
import notify from "./notify"
export default function useProduct(){

    
    const loading = ref(false)
    const product = ref([]);
    const form = ref({
        id: 0,
        marque :null,
        forme_galenique :null,
        designation :null,
        dosage :null,
        unite_de_mesure :null,
        prix_achat :null,
        prix_de_vente :null,
        ean :null,
    })

    const serverError = ref(false)

    const handleSave = async() =>{
        loading.value = true
        if(form.value.id > 0){
            try {
                const resp = await useHttp.put(`product/${form.value.id}`,form.value)
                
                if(resp.status === 201){
                    notify.isOk(resp.data.data)
                    document.getElementById('close').click()
                }
                   
                
            } catch (error) {
                console.log(error);
                serverError.value = true
            }
        }else{
            try {
                const resp = await useHttp.post('product',form.value)
                
                if(resp.status === 201){
                    notify.isOk(resp.data.data)
                    document.getElementById('close').click()
                }
                   
                
            } catch (error) {
                console.log(error);
                serverError.value = true
            }
        }
        loading.value = false

        document.querySelector(".refreshTable").click();
    }

    const getProduct = async () =>{
        loading.value = true
        try {
            const res = await useHttp.get('product');

            product.value = res.data.data;
            
        } catch (error) {
            console.log(error);
            
        }
        loading.value = false
    }


    return {
        form,
        loading,
        handleSave,
        product,
        getProduct,
        serverError,
    }
}