<template>
   <DefaultView>
    <div class="row justify-content-end mr-1">
            <button type="button" class="btn btn-sm btn-primary mb-2 mr-2 refreshTable" @click="refresh">
                Actualiser &nbsp; <i class="fas fa-sync"></i>
            </button>
            <button type="button" class="btn btn-sm btn-primary mb-2" data-toggle="modal" data-target="#modal-employe">
                Nouveau <i class="nav-icon fas fa-plus"></i>
            </button>
      </div>
      <template v-if="loading">
        <DataLoader />
      </template>
      <template v-else>
        <template v-if="servError">
            <ServerDisconnect :but="getEmploye" />
        </template>
        <template v-else>
            <EmployeTableView :data="employe" />
        </template>
      </template>
        <EmployeModal />
   </DefaultView>
</template>

<script setup>
import DefaultView from '@/layouts/DefaultView.vue';
import useEmploye from "@/composition/useEmploye";
import { onMounted } from 'vue';
import DataLoader from "@/components/DataLoader.vue"
import ServerDisconnect from "@/components/ServerDisconnect.vue"
import EmployeTableView from "@/components/datatables/employe/EmployeTableView.vue"
import EmployeModal from "@/components/modals/EmployeModal.vue";


const {employe, getEmploye, loading, servError } = useEmploye()



onMounted(()=>{getEmploye()})

const refresh = () => getEmploye();
</script>

