<template>
    <DefaultView >
      <div class="row justify-content-end mr-1">
        <button type="button" class="btn btn-sm btn-success" data-toggle="modal" data-target="#modal-Delivery">
            Nouveau <i class="nav-icon fas fa-plus"></i>
        </button>
      </div>

      <template v-if="loading">
        <DataLoader />
      </template>
      <template v-else>
        
        <template v-if="servError">
            <ServerDisconnect :but="getDelivery" />
        </template>

        <template v-else>
            <DataTableView :data="data" />
        </template>
      </template>
      <DeliveryModal />
    </DefaultView>
</template>

<script setup>
    import DefaultView from '@/layouts/DefaultView.vue'
    import { onMounted } from 'vue';
    import useDelivery from '../../composition/useDelivery';
    import DataLoader from '@/components/DataLoader.vue';
    import ServerDisconnect from '@/components/ServerDisconnect.vue';
    import DataTableView from '@/components/datatables/delivery/DataTableView.vue';
  import DeliveryModal from '../../components/modals/DeliveryModal.vue';

    const { data, loading, getDelivery,servError }= useDelivery()

    onMounted(()=>{
        getDelivery()
    })
</script>

<style lang="scss" scoped>
</style>