import ad from '@/views/admin/index'
import auth from '@/views/auth/index'
const routes = [
  { path: '/', name: 'login', component: auth.LoginVue, meta : { auth: false }},

  { path: '/mot_de_passe_oublié',name: 'password',component: auth.PasswordVue, meta : { auth: false }},

  { path: '/dashboard', name: 'home',component: ad.HomeView, meta : { auth:true } },

  { path:'/produit', name:'product', component:ad.ProductView,meta : { auth:true }},

  { path:'/client', name:'costumer', component:ad.CostumerView ,meta : { auth:true }},

  { path:'/stock', name:'stock', component:ad.StockProduct, meta : { auth:true }},

  { path:'/mlmcommande', name:'mlmorder', component:ad.MlmOrder, meta : { auth:true }},

  { path: '/livreur', name: 'delivery', component: ad.DeliveryView, meta: { auth:true } },

  { path: '/employe', name: 'employe',component: ad.EmployeView, meta : {auth:true} },

  { path: '/entreprise', name: 'company', component: ad.CompanyView, meta : {auth:true}},

  { path: '/shopify', name: 'shopify', component: ad.ShopifyView, meta : {auth:true} }
]


export default routes
