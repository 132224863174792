<template>
  <div class="bg-white cp-table">

      <div class="row d-flex justify-content-between mb-1">
          <div class="col-7">
              <p class="ml-3" v-if="data.length"><i class="nav-icon fas fa-database text-success"></i> {{data.length}} Employé(s)</p>
          </div>
          <div class="col-3">
               <!-- Zone de recherche -->
              <input v-model="searchQuery" placeholder="Rechercher..." class="form-control" />
          </div>
      </div>
    <!-- Tableau des données -->
      <div class="card-body table-responsive p-0" style="height: 65vh;">
          <table class="table table-striped table-hover text-nowrap">
              <thead class="bg-hp-green sticky-top top-0">
                  <tr>
                      <th>#</th>
                      <th scope="col">Matricule</th>
                      <th scope="col">Nom</th>
                      <th scope="col">Prénoms</th>
                      <th scope="col">Date de naissance</th>
                      <th scope="col">Sexe</th>
                      <th scope="col">Téléphone</th>
                      <th scope="col">Téléphone 2</th>
                      <th scope="col">Adresse email</th> 
                      <th scope="col">Date d'embauche</th>
                      <th scope="col">Fonction</th>
                      <th scope="col"></th>
                  </tr>
              </thead>
              <tbody>
                  <template v-if="paginatedData.length">
                      <tr v-for="(item, index) in paginatedData" :key="index">
                          <td>{{ countItem(index) }}</td>
                              <td>{{ item.matricule }}</td>
                              <td>{{ item.nom }}</td>
                              <td>{{ item.prenoms }}</td>
                              <td>{{ item.date_de_naissance }}</td>
                              <td>{{ item.sexe }}</td>
                              <td>{{ item.telephone }}</td>
                              <td>{{ item.telephone_2 }}</td>
                              <td>{{ item.email }}</td>
                              <td>{{ item.date_embauche }}</td>
                              <td>{{ item.fonction }}</td>
                              <td>
                                  <div class="btn-group btn-group-sm" role="group" aria-label="Small button group">
                                      <button type="button" class="btn btn-outline-primary" @click="handleShow(item)"><i class="nav-icon fas fa-edit"></i></button>
                                      <button type="button" class="btn btn-outline-danger" @click="handleDelete(item)"><i class="nav-icon fas fa-trash"></i></button>
                                  </div>
                              </td>
                      </tr>
                  </template>
                  <template v-else>
                  <tr>
                      <td colspan="11" class="text-center"><span class="text-center mt-5"><i class="nav-icon fas fa-exclamation-triangle text-warning mr-3"></i>Aucune information trouvée !</span></td>
                  </tr>
                      
                  </template>
              </tbody>
          </table>
      </div>
    <!-- Pagination -->
      <div class="d-flex flex-row-reverse">
          <nav aria-label="Page navigation example">
              <ul class="pagination justify-content-end">
          
                  <li :class="`page-item ${currentPage === 1 ? 'disabled' : ''}`" >
                      <a class="page-link" @click="previousPage" >Précédent</a>
                  </li>
                  <li class="page-item">
                      <a class="page-link" href="#">{{ currentPage }}</a>
                  </li>
                  <li class="page-item">
                      <a class="page-link" href="#">/ </a>
                  </li>
                  <li class="page-item">
                      <a class="page-link" href="#">{{ totalPages }}</a>
                  </li>
                  <li :class="`page-item ${currentPage === totalPages ? 'disabled' : ''}`">
                      <a class="page-link" @click="nextPage" >Suivant</a>
                  </li>
              </ul>
          
          </nav>
          <ul class="pagination justify-content-end mr-1"> 
          <p class="text-left mr-2 mt-2"> Afficher par nombre de </p>
              <select v-model="itemsPerPage" class="form-control" style="width: 5rem;">
                  <option :value="10">10 </option>
                  <option :value="25">25 </option>
                  <option :value="50">50 </option>
                  <option :value="100">100 </option>
                  <option :value="150">150 </option>
              </select>
          </ul>
  
      </div>
  </div>
  <EmployeModal :data="updatedData" />
  <DeleteModal :items="deletedData" />
</template>

<script setup>
  import { ref, computed } from 'vue';
  import { Modal } from "bootstrap/dist/js/bootstrap.bundle";
  import EmployeModal from '@/components/modals/EmployeModal.vue';
  import DeleteModal from '@/components/delete/DeleteModal.vue';

    const searchQuery = ref('');
    const currentPage = ref(1);
    const itemsPerPage = ref(10);
    
    // Simule des données
    const props = defineProps({
      data:{
        type: Array,
        required:false
      }
    })

    // Données réactives pour la recherche et la pagination
 
    // Filtrer les données en fonction de la recherche
    const filteredData = computed(() => {
      let items = props.data
      if (searchQuery.value) {
        items = props.data.filter((item) =>
          item.matricule != null && item.matricule.toLowerCase().includes(searchQuery.value.toLowerCase()) ||
          item.nom != null && item.nom.toLowerCase().includes(searchQuery.value.toLowerCase()) ||
          item.prenoms != null && item.prenoms.toLowerCase().includes(searchQuery.value.toLowerCase()) ||
          item.date_de_naissance != null && item.date_de_naissance.toLowerCase().includes(searchQuery.value.toLowerCase()) ||
          item.sexe != null && item.sexe.toLowerCase().includes(searchQuery.value.toLowerCase()) ||
          item.telephone != null && item.telephone.toLowerCase().includes(searchQuery.value.toLowerCase()) ||
          item.email != null && item.email.toLowerCase().includes(searchQuery.value.toLowerCase()) ||
          item.date_embauche != null && item.date_embauche.toLowerCase().includes(searchQuery.value.toLowerCase()) ||
          item.fonction != null && item.fonction.toLowerCase().includes(searchQuery.value.toLowerCase()) 
          // item.adresse_email != null && item.adresse_email.toLowerCase().includes(searchQuery.value.toLowerCase()) 
        );
      }
      return items;
    });

    // Calculer les données paginées
    const paginatedData = computed(() => {
      const start = (currentPage.value - 1) * itemsPerPage.value;
      const end = start + itemsPerPage.value;
      return filteredData.value.slice(start, end);
    });

    // Nombre total de pages
    const totalPages = computed(() => {
      return Math.ceil(filteredData.value.length / itemsPerPage.value);
    });

    // Aller à la page précédente
    const previousPage = () => {
      if (currentPage.value > 1) {
        currentPage.value--;
      }
    };

    // Aller à la page suivante
    const nextPage = () => {
      if (currentPage.value < totalPages.value) {
        currentPage.value++;
      }
    };

   const countItem = (index) => (index + 1 + (currentPage.value - 1) * itemsPerPage.value)


    //modification
   const updatedData=ref([]);

   const handleShow = (item) => {
      //     // Affecter les valeurs des données dans item au formulaire
      updatedData.value = item
          const modalElement = document.getElementById("modal-employe");
    
            let modal = Modal.getInstance(modalElement); // Vérifier si une instance existe déjà
            if (!modal) {
              modal = new Modal(modalElement); // Si elle n'existe pas, la créer
            }
    
          modal.show();
        }


    const deletedData=ref();
   const handleDelete = (item) => {
      //     // Affecter les valeurs des données dans item au formulaire
      deletedData.value = {
          url: 'employe',
          id : item.id,
          title : 'cet employé',
          name : `${item.nom} ${item.prenoms}`
      }
          const modalElement = document.getElementById("modal-delete");
    
            let modal = Modal.getInstance(modalElement); // Vérifier si une instance existe déjà
            if (!modal) {
              modal = new Modal(modalElement); // Si elle n'existe pas, la créer
            }
    
          modal.show();
        }

</script>

<style scoped>

a{
  cursor: pointer;
}
.bg-hp-orange{
  background-color: #FBB03B !important;
  color:#fff
}
.bg-hp-green{
  background-color: #0D7D62 !important;
  color:#fff
}
.cp-table{
  border: 1px solid #d2d0d0b3;
  border-radius: 5px;
  padding: 3px;
}
</style>
