<template>
    <div class="hold-transition login-page">
    <div class="login-box">
<div class="login-logo">
    <a href="#" class="h1"><b style="color: #FBB03B;">Harmopharm</b> <span style="color:#0D7D62">SAS</span></a>
</div>

<div class="card">
<div class="card-body login-card-body">
<p class="login-box-msg">Tu as oublié ton mot de passe ? ici tu peux le retrouver facilement.</p>
<form method="post">
<div class="input-group mb-3">
<input type="email" class="form-control" placeholder="Adresse email">
<div class="input-group-append">
<div class="input-group-text">
<span class="fas fa-envelope"></span>
</div>
</div>
</div>
<div class="row">
<div class="col-12">
<button type="submit" class="btn btn-primary btn-block">Reinitialiser le mot de passe</button>
</div>

</div>
</form>
    <div class="row mt-2">
        <div class="col-12">
            
            <button @click.prevent="Handlelogin" class="btn btn-warning btn-block">J'ai déjà un compte</button>
        </div>
    </div>

</div>

</div>
</div>
</div>
</template>


<script setup>
import useAuth from '@/composition/useAuth';

const { Handlelogin } = useAuth()
</script>

<style lang="css" scoped>
.login-page{
    background-image: url('@/assets/img/comp.jpg');
    background-repeat: no-repeat;
    background-size: cover;
}
</style>