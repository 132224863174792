import HomeView from "./HomeView.vue";
import CompanyView from './CompanyView.vue'
import ShopifyView from './ShoipfyView.vue'
import DeliveryView from "./DeliveryView.vue";
import EmployeView from "./EmployeView.vue"
import CostumerView from "./CostumerView.vue"
import MlmOrder from "./MlmOrder.vue"
import ProductView from "./ProductView.vue"
import StockProduct from "./StockProduct.vue"

export default {
    HomeView,
    CompanyView,
    ShopifyView,
    DeliveryView,
    EmployeView,
    CostumerView,
    MlmOrder,
    ProductView,
    StockProduct
}