import useHttp from "@/api";
import { useAuthStore } from "@/store";
import { ref } from "vue";
import notify from "./notify";

export default function useCostumer(){
    const loading = ref(false)
    const servError = ref(false)
    const errors = ref()

    const store = useAuthStore()

    const costumers = ref([]);


    const form = ref({
        id: 0,
        nom_client : null,
        prenom_client : null,
        sexe : null,
        telephone_client : null,
        telephone_2_client : null,
        pays : null,
        ville : null,
        adresse_rue : null,
        adresse_email : null,
        entreprise : store.user.entreprise_id
    })

    const handleSave = async()=>{
        loading.value = true
        
        if(form.value.id > 0){
            try {
                const res = await useHttp.put(`costumer/${form.value.id}`, form.value);
    
                if (res.status===201){
                    notify.isOk(res.data.data)
                    
                }
            } catch (error) {
                errors.value =error;
            }
        }else{
            try {
                form.value.entreprise = store.user.entreprise_id
                const res = await useHttp.post('costumer', form.value);
    
                if (res.status===201){
                    notify.isOk(res.data.data)
                }
            } catch (error) {
                errors.value =error;
                
            }
        }
        
        document.querySelector(".refreshTable").click()
        document.querySelector(".close").click();
        
        loading.value = false
    }

    const getCostumers =async()=>{
        loading.value = true 
        servError.value = false
        try {
            const res = await useHttp.get('costumer')
        
            costumers.value = res.data.data
        } catch (error) {
            errors.value =error;
            servError.value = true
        }

        loading.value = false
    }

    return {
        form,
        loading,
        handleSave,
        costumers,
        getCostumers,
        errors,
        servError
    }
}