<template>
    <DefaultView>
        <div class="row justify-content-end mr-1">
            <button type="button" class="btn btn-sm btn-success mb-2" data-toggle="modal" data-target="#modal-order">
                Nouvelle commande <i class="nav-icon fas fa-plus"></i>
            </button>
        </div>

        <MlmTableView :data="data" />
    </DefaultView>
</template>

<script setup>
    import DefaultView from "@/layouts/DefaultView.vue"
    import MlmTableView from "@/components/datatables/mlm/MlmTableView.vue";


    const data=[
        {id: 1, el1: 'Element 1'},
        {id: 2, el1: 'Element 2'},
        {id: 3, el1: 'Element 3'},
    ]
</script>

<style lang="scss" scoped>

</style>