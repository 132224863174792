<template>
    <DefaultView>
        <template v-if="loading">
            <DataLoader />
        </template>
        <template v-else>
           <template v-if="errors">
                <ServeurDisconnect :but="getShopifyData()" />
           </template>
            <template v-else>
                <div class="d-flex justify-content-end mb-2">
                    <div class="col-5">
                        <SelectDelivery :options="deliveryData" @returnId="getIdDeliver"/>
                    </div>
                    <button type="button" class="btn btn-success" @click.prevent="handleAffectedDelivery" :disabled="loadingaff">
                        <div class="spinner-border spinner-border-sm" role="status" v-if="loadingaff" ></div>
                        Affecter <i class="fas fa-save nav-icon"></i>
                    </button>
                </div>
                <ShopifyTableView :data="data" @returnSelectedData="getOrderSelected"/>
            </template>
        </template>
       
    </DefaultView>
</template>

<script setup>

import { onMounted, ref } from 'vue';
import useShopify from '../../composition/useShopify';
import DefaultView from '@/layouts/DefaultView.vue';
import DataLoader from '@/components/DataLoader.vue'
import ServeurDisconnect from '@/components/ServerDisconnect.vue'
import ShopifyTableView from '@/components/datatables/shopify/ShopifyTableView.vue'
import SelectDelivery from '@/components/select/SelectDeliveryView.vue'
import { useAuthStore } from '../../store';

const { getShopifyData,getDelivery, handlesaveShopify, data , loading, errors, deliveryData,loadingaff  } = useShopify()

const store = useAuthStore()

onMounted( async() =>{
    await getShopifyData()
    await getDelivery()
})
const orderSelected = ref()

const getOrderSelected = (order)=>{
    
    orderSelected.value= order.value
}

const idDelivery = ref(0)

const getIdDeliver = (id) => idDelivery.value =id;

const handleAffectedDelivery = async() => {
    const data = ref({})
    data.value = {...orderSelected.value}

    Object.keys(data.value).forEach(key => {
        data.value[key].idUtilisateur = store.user.id;
        data.value[key].idLivreur =idDelivery.value;
        data.value[key].idEntreprise = 1;
        });
    
    await handlesaveShopify(data.value);
    await getShopifyData()
    
}
</script>

<style lang="css" scoped>

</style>