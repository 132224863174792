<template>
   <DefaultView>
    <div class="row justify-content-end mr-1">
            <button type="button" class="btn btn-sm btn-primary mb-2 mr-2 refreshTable" @click="refresh">
                Actualiser &nbsp; <i class="fas fa-sync"></i>
            </button>
            <button type="button" class="btn btn-sm btn-primary mb-2" data-toggle="modal" data-target="#modal-product">
                Nouveau &nbsp; <i class="nav-icon fas fa-plus"></i>
            </button>
        </div>
        <template v-if="loading">
        <DataLoader />
      </template>
      <template v-else>
        
        <template v-if="serverError">
            <ServerDisconnect :but="getProduct" />
        </template>

        <template v-else>
            <ProductTableView :data="product" />
        </template>
      </template>
           
                
             <ProductModal />
   </DefaultView>
</template>

<script setup>
    import DefaultView from "@/layouts/DefaultView.vue"
    import ProductTableView from "@/components/datatables/produit/ProductTableView.vue";
    import ProductModal from "../../components/modals/ProductModal.vue";
    import useProduct from "@/composition/useProduct";
    import DataLoader from "@/components/DataLoader.vue"
    import ServerDisconnect from "@/components/ServerDisconnect.vue"
    import { onMounted } from "vue";

    const { getProduct, loading, product, serverError } = useProduct()


    onMounted(()=>{
        getProduct()
    })


    const refresh = async() => await getProduct();
</script>

<style lang="scss" scoped>

</style>