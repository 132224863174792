<template>
    <div class="modal fade" id="modal-employe">
        <div class="modal-dialog modal-lg">
            <div class="modal-content">
                <div class="modal-header">
                    <h4 class="modal-title">Employé(e)</h4>
                    <button type="button" class="close" data-dismiss="modal" aria-label="Close" @click="handleClose" id="close-employe">
                        <span aria-hidden="true">&times;</span>
                    </button>
                </div>
                <form action="#">
                    <div class="modal-body">
                    <div class="row">
                        <div class="col-6">
                            <div class="form-group">
                                <label for="nom">Nom</label>
                                <input type="text" v-model="form.nom" class="form-control" id="nom" placeholder="Nom" style="text-transform: uppercase;">
                            </div>
                        </div>
                        <div class="col-6">
                            <div class="form-group">
                                <label for="prenoms">Prénoms</label>
                                <input type="text" v-model="form.prenoms" class="form-control" id="prenoms" placeholder="Prénoms" style="text-transform: capitalize;">
                                
                            </div>
                        </div>
                        
                    </div>
                    <div class="row">
                        <div class="col-6">
                            <div class="form-group">
                                <label for="date_naiss">Date de naissance</label>
                                <input type="date" v-model="form.date_de_naissance" class="form-control dateHp" id="date_naiss" placeholder="Date de naissance">
                            </div>
                        </div>
                        
                        <div class="col-3">
                            <div class="form-group">
                                <label for="sexe">Sexe</label>
                                <select v-model="form.sexe"  class="form-control" id="sexe">
                                    <option value="Homme">Homme</option>
                                    <option value="Femme">Femme</option>
                                </select>
                            </div>
                        </div>
                        <div class="col-3">
                            <div class="form-group">
                                <label for="tel">Téléphone</label>
                                <input type="text" v-model="form.telephone" class="form-control" id="tel" placeholder="Téléphone">
                                
                            </div>
                        </div>
                    </div>
                    <div class="row">
                        <div class="col-6">
                            <div class="form-group">
                                <label for="telephone2">Téléphone 2</label>
                                <input type="text" v-model="form.telephone_2" class="form-control" id="telephone2" placeholder="Téléphone 2">
                            </div>
                        </div>
                        <div class="col-6">
                            <div class="form-group">
                                <label for="email">Adresse email</label>
                                <input type="email" v-model="form.email" class="form-control" id="email" placeholder="Adresse email">
                            </div>
                        </div>
                        
                    </div>
                    <div class="row">
                        <div class="col-6">
                            <div class="form-group">
                                <label for="date_emb">Date d'embauche</label>
                                <input type="date" v-model="form.date_embauche" class="form-control" id="date_emb" placeholder="Date d'embauche">
                            </div>
                        </div>
                        <div class="col-6">
                            <div class="form-group">
                                <label for="fonct">Fonction</label>
                                <input type="text" v-model="form.fonction" class="form-control" id="fonct" placeholder="Fonction">
                                <!-- <SelectVieW :options="dataCompany" @return-id="handleGetId" /> -->
                            </div>
                        </div>
                        
                    </div>
                   
                </div>
                <div class="modal-footer justify-content-between">
                    <button type="button" class="btn btn-default" data-dismiss="modal" aria-label="Close" @click="handleClose">fermer</button>
                    <button type="button" class="btn btn-success" @click.prevent="handleSave" :disabled="loading">
                        <div class="spinner-border spinner-border-sm" role="status" v-if="loading" ></div>
                        Enregistrer <i class="fas fa-save nav-icon"></i>
                    </button>
                </div>
                </form>
            </div>
        </div>
    </div>       
</template>


<script setup>
import { ref, watch } from 'vue';
import { useAuthStore } from '@/store';
import useEmploye from '../../composition/useEmploye';
import { Modal } from "bootstrap/dist/js/bootstrap.bundle";


    const { form, handleSave, loading } = useEmploye()


    const props = defineProps({
        data:{
            type:Object,
            require:false
        }
    })


    form.value = { ...props.data };
    watch(() => props.data, (newData) => {
        form.value = { ...newData };
        
    });


    const emit = defineEmits(['update'])

    const store = useAuthStore()

    const formData = ref({
        id: 0,
        nom: null,
        prenoms: null,
        date_de_naissance: null,
        sexe: null,
        telephone: null,
        telephone_2: null,
        email: null,
        date_embauche: null,
        fonction: null,
        entreprise_id : store.user.entreprise_id
    })

    const handleClose = () => {
    const modalElement = document.getElementById("modal-employe");
    form.value = {...formData.value};
    
    // Si une instance du modal existe déjà
        const modal = Modal.getInstance(modalElement); // Récupérer l'instance du modal existante
        emit('update',false)
        if (modal) {
            modal.hide(); // Ferme le modal
        }
    }
</script>