import { ref } from "vue";
import useHttp from "../api";
import notify from "./notify";

export default function useDelivery(){
    const data = ref([]);
    const loading = ref(false)
    const servError = ref(false)
    const dataCompany = ref([]);


    const form = ref({
        nom:null,
        prenoms:null,
        date_embauche:null,
        telephone1:null,
        telephone2:null,
        sexe:null,
        entreprise:null,
        date_de_naissance:null,
    })


    const getDelivery = async () => {
        loading.value = true
        servError.value = false
        try {
            const result  = await useHttp.get('delivery');
            data.value = await result.data.data
            
        } catch (error) {
            servError.value = true
            notify.isNotOk(error.status)
        }
        
        loading.value = false
    }

    const handleCompany = async()=>{
        const res = await useHttp.get('company')
        dataCompany.value = await res.data.data
    }

    const handleSave = async() =>{
         
        loading.value = true 
            try {
                const result = await useHttp.post('delivery',form.value)
                if(result.status ===200){
                    notify.isOk(result.message)
                   await getDelivery()
                   document.getElementById('close').click()
                }else{
                    notify.isNotOk(result.status)
                }
            } catch (error) {
                console.log(error);
                notify.isNotOk(error.status)
            }
        loading.value = false
    }

    return {
        data,
        dataCompany,
        loading,
        servError,
        form,
        getDelivery,
        handleSave,
        handleCompany
    }
}