<template>
    <div class="modal fade" id="modal-product">
        <div class="modal-dialog modal-lg">
            <div class="modal-content">
                <div class="modal-header">
                    <h4 class="modal-title">Produit</h4>
                    <button type="button" class="close" data-dismiss="modal" aria-label="Close" @click="handleClose">
                        <span aria-hidden="true">&times;</span>
                    </button>
                </div>
                <form action="#">
                <input type="hidden" v-model="form.id">
                    <div class="modal-body">
                    <div class="row">
                        <div class="col-6">
                            <div class="form-group">
                                <label for="mq">Marque</label>
                                <input type="text" v-model="form.marque" class="form-control" id="mq" placeholder="Marque"  style="text-transform: capitalize;">
                            </div>
                        </div>
                        <div class="col-6">
                            <div class="form-group">
                                <label for="des">Désignation du produit</label>
                                <input type="text" v-model="form.designation" class="form-control" id="des" placeholder="Désignation du produit">
                                 
                            </div>
                        </div>
                        
                    </div>
                    <div class="row">
                        <div class="col-6">
                            <div class="form-group">
                                <label for="fg">Forme galenique</label>
                                <input type="text" v-model="form.forme_galenique" class="form-control dateHp" id="fg" placeholder="Forme galenique">
                            </div>
                        </div>
                        <div class="col-3">
                            <div class="form-group">
                                <label for="dosage">Dosage</label>
                                <input type="text" v-model="form.dosage" class="form-control" id="dosage" placeholder="Dosage">
                                
                            </div>
                        </div>
                        <div class="col-3">
                            <div class="form-group">
                                <label for="um">Unité de mesure</label>
                                <input type="text" v-model="form.unite_de_mesure" class="form-control" id="um" placeholder="unité de mesure">
                            </div>
                        </div>
                        
                    </div>
                    <div class="row">
                        <div class="col-3">
                            <div class="form-group">
                                <label for="pa">Prix d'achat</label>
                                <input type="text" v-model="form.prix_achat" class="form-control" id="pa" placeholder="Prix d'achat">
                            </div>
                        </div>
                        <div class="col-3">
                            <div class="form-group">
                                <label for="pv">Prix de vente</label>
                                <input type="text" v-model="form.prix_de_vente" class="form-control" id="pv" placeholder="Prix de vente">
                            </div>
                        </div>
                        <div class="col-6">
                            <div class="form-group">
                                <label for="ea">EAN</label>
                                <input type="text" v-model="form.ean" class="form-control" id="ea" placeholder="EAN">
                            </div>
                        </div>
                    </div>
                  
                    </div>
                <div class="modal-footer justify-content-between">
                    <button type="button" class="btn btn-default" data-dismiss="modal" id="close" @click="handleClose">fermer</button>
                    <button type="button" class="btn btn-success" @click.prevent="saveData" :disabled="loading">
                        <div class="spinner-border spinner-border-sm" role="status" v-if="loading" ></div>
                        Enregistrer <i class="fas fa-save nav-icon"></i>
                    </button>
                </div>
                </form>
            </div>
        </div>
    </div>       
</template>


<script setup>
import { ref, watch } from 'vue';
import useProduct from '../../composition/useProduct';
import { Modal } from "bootstrap/dist/js/bootstrap.bundle";


    const { form, handleSave, loading } = useProduct()

    const emit = defineEmits(['update'])

    const saveData = async()=>{
        await handleSave()

        emit('update',true)
    }

    const props = defineProps({
        data:{
            type:Object,
            require:false
        }
    })


    form.value = { ...props.data };
    watch(() => props.data, (newData) => {
        form.value = { ...newData };
        
    });

    const formData = ref({
        id: 0,
        marque :null,
        forme_galenique :null,
        designation :null,
        dosage :null,
        unite_de_mesure :null,
        prix_achat :null,
        prix_de_vente :null,
        ean :null,
    })

    const handleClose = () => {
    const modalElement = document.getElementById("modal-product");
    form.value = {...formData.value};
    // Si une instance du modal existe déjà
        const modal = Modal.getInstance(modalElement); // Récupérer l'instance du modal existante
        emit('update',false)
        if (modal) {
            modal.hide(); // Ferme le modal
        }
    }
   
</script>