import useHttp from "@/api";
import { useAuthStore } from "@/store";
import { ref } from "vue";
import notify from "./notify";

export default function useEmploye(){
    const loading = ref(false)
    const servError = ref(false)
    const errors = ref()

    const store = useAuthStore()

    const employe = ref([]);


    const form = ref({
        id: 0,
        nom: null,
        prenoms: null,
        date_de_naissance: null,
        sexe: null,
        telephone: null,
        telephone_2: null,
        email: null,
        date_embauche: null,
        fonction: null,
        entreprise_id : null
    })

    const handleSave = async()=>{
        loading.value = true
        
        if(form.value.id > 0){
            try {
                form.value.entreprise_id = store.user.entreprise_id
                const res = await useHttp.put(`employe/${form.value.id}`, form.value);
    
                if (res.status===201){
                    notify.isOk(res.data.data)
                    
                }
            } catch (error) {
                errors.value =error;
            }
        }else{
            try {
                form.value.entreprise_id = store.user.entreprise_id
                const res = await useHttp.post('employe', form.value);
    
                if (res.status===201){
                    notify.isOk(res.data.data)
                }
            } catch (error) {
                errors.value =error;
                
            }
        }
        
        loading.value = false
        document.querySelector(".refreshTable").click()

        document.querySelector("#close-employe").click();
    }

    const getEmploye =async()=>{
        loading.value = true 
        servError.value = false
        try {
            const res = await useHttp.get('employe')
        
            employe.value = res.data.data
        } catch (error) {
            errors.value =error;
            servError.value = true
        }

        loading.value = false
    }

    return {
        form,
        loading,
        handleSave,
        employe,
        getEmploye,
        errors,
        servError
    }
}