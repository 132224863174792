import { defineStore } from "pinia";
import { ref } from "vue";
// import { useRouter } from "vue-router";

export const useAuthStore = defineStore('authStore',() =>{
    
    // const router = useRouter()
       
    const token = ref(null)
    const user = ref(null)
    const isAthenticated = ref(false)

    const setData = async(resul) =>{

        user.value = resul.data.data.user
        token.value = resul.data.data.token
        isAthenticated.value = true

        // router.push({name : 'home'});
        
    }
    const reset = () =>{
        user.value = null
        token.value = null
        isAthenticated.value = false
      }

    return {
        user,
        token,
        setData,
        isAthenticated,
        reset
    }
     

},{persist: true,})