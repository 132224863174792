<template>
  <div class="bg-white cp-table mt-1">

      <div class="row d-flex justify-content-between mb-1">
          <div class="col-7">
              <p class="ml-3 mt-2"><i class="nav-icon fas fa-database text-success"></i> {{props.data.length}} Filiale(s)</p>
          </div>
          <div class="col-3 mt-2">
               <!-- Zone de recherche -->
              <input v-model="searchQuery" placeholder="Rechercher..." class="form-control" />
          </div>
      </div>
    <!-- Tableau des données -->
      <div class="card-body table-responsive p-0" style="height: 58vh; overflow-x: auto;">
          <table class="table text-nowrap">
              <thead class="bg-hp-green sticky-top top-0">
                  <tr class="bg-hp-green">
                      <th>#</th>
                      <th scope="col">Raison social</th>
                      <th scope="col">Pays</th>
                      <th scope="col">Code</th>
                      <th scope="col">Ville</th>
                      <th scope="col">Adresse</th>
                      <th scope="col">Email</th>
                      <th scope="col">Standard</th> 
                      <th scope="col">Responsable</th>
                      <th scope="col">Téléphone responsable</th>
                      <th scope="col"></th>
                  </tr>
              </thead>
              <tbody>
                  <template v-if="paginatedData.length">
                      <tr v-for="(item, index) in paginatedData" :key="index" @click="selectRow(item, $event)"
                      :class="{ selected: isSelected(item) }">
                      <td>{{ countItem(index) }}</td>
                              <td>{{ item.raison_sociale }}</td>
                              <td>{{ item.pays }}</td>
                              <td>{{ item.code }}</td>
                              <td>{{ item.ville }}</td>
                              <td>{{ item.adresse }}</td>
                              <td>{{ item.email }}</td>
                              <td>{{ item.telephone_standard }}</td>
                              <td>{{ item.nom_responsable }}</td>
                              <td>{{ item.telephone_responsable }}</td>
                              <td>
                                  <div class="btn-group btn-group-sm" role="group" aria-label="Small button group">
                                      <button type="button" class="btn btn-outline-primary modify" @click="handleShow(item)"><i class="nav-icon fas fa-edit"></i></button>
                                      <button type="button" class="btn btn-outline-danger"><i class="nav-icon fas fa-trash"></i></button>
                                  </div>
                              </td>
                      </tr>
                  </template>
                  <template v-else>
                  <tr>
                      <td colspan="13" class="text-center"><span class="text-center mt-5"><i class="nav-icon fas fa-exclamation-triangle text-warning mr-3"></i>Aucune information trouvée !</span></td>
                  </tr>
                      
                  </template>
              </tbody>
          </table>
      </div>
    <!-- Pagination -->
      <div class="d-flex flex-row-reverse">
          <nav aria-label="Page navigation example">
              <ul class="pagination justify-content-end mt-2">
          
                  <li :class="`page-item ${currentPage === 1 ? 'disabled' : ''}`" >
                      <a class="page-link" @click="previousPage" >Précédent</a>
                  </li>
                  <li class="page-item">
                      <a class="page-link" href="#">{{ currentPage }}</a>
                  </li>
                  <li class="page-item">
                      <a class="page-link" href="#">/ </a>
                  </li>
                  <li class="page-item">
                      <a class="page-link" href="#">{{ totalPages }}</a>
                  </li>
                  <li :class="`page-item ${currentPage === totalPages ? 'disabled' : ''}`">
                      <a class="page-link" @click="nextPage" >Suivant</a>
                  </li>
              </ul>
          
          </nav>
          <ul class="pagination justify-content-end mt-2 mr-2"> 
          <p class="text-left mt-2 mr-2"> Afficher par nombre de </p>
              <select v-model="itemsPerPage" class="form-control" style="width: 5rem;">
                  <option :value="10">10 </option>
                  <option :value="15">15 </option>
                  <option :value="25">25 </option>
                  <option :value="50">50 </option>
                  <option :value="100">100 </option>
                  <option :value="150">150 </option>
              </select>
          </ul>
  
      </div>
  </div>
      <CompanyModal :data="dataVal" />
</template>

<script setup>
  import { ref, computed } from 'vue';
  import CompanyModal from '@/components/modals/CompanyModal.vue'
  import { Modal } from "bootstrap/dist/js/bootstrap.bundle";
  
    const dataVal = ref()
    const handleShow = (item) => {
      //     // Affecter les valeurs des données dans item au formulaire
      dataVal.value = item
          const modalElement = document.getElementById("modal-company");
    
            let modal = Modal.getInstance(modalElement); // Vérifier si une instance existe déjà
            if (!modal) {
              modal = new Modal(modalElement); // Si elle n'existe pas, la créer
            }
    
          modal.show();
        }
    
  const props = defineProps({
    data:{
      type: Array,
      required:true
    }
  })

    // Données réactives pour la recherche et la pagination
    const searchQuery = ref('');
    const currentPage = ref(1);
    const itemsPerPage = ref(10);

    // Filtrer les données en fonction de la recherche
    const filteredData = computed(() => {
      let items = props.data
      if (searchQuery.value) {
        items = props.data.filter((item) =>
          item.raison_sociale != null && item.raison_sociale.toLowerCase().includes(searchQuery.value.toLowerCase()) ||
          item.pays != null && item.pays.toLowerCase().includes(searchQuery.value.toLowerCase())||
          item.code != null && item.code.toLowerCase().includes(searchQuery.value.toLowerCase())||
          item.pays != null && item.pays.toLowerCase().includes(searchQuery.value.toLowerCase())||
          item.ville != null && item.ville.toLowerCase().includes(searchQuery.value.toLowerCase())||
          item.adresse != null && item.adresse.toLowerCase().includes(searchQuery.value.toLowerCase()) ||
          item.email != null && item.email.toLowerCase().includes(searchQuery.value.toLowerCase())||
          item.tel_standard != null && item.tel_standard.toLowerCase().includes(searchQuery.value.toLowerCase())||
          item.responsable != null && item.responsable.toLowerCase().includes(searchQuery.value.toLowerCase())||
          item.telphone_resp != null && item.telphone_resp.toLowerCase().includes(searchQuery.value.toLowerCase())

          
        );
      }
      return items;
    });
    
   

    const emit = defineEmits(['returnSelectedData'])

    const selectedItems = ref([]);

    const selectRow = (item, event) => {
    if (event.ctrlKey || event.metaKey) {  // Pour macOS, utilisez également `event.metaKey`
      const index = selectedItems.value.findIndex(selectedItem => selectedItem.id === item.id);
      if (index !== -1) {
        selectedItems.value.splice(index, 1); // Déselectionner si déjà sélectionné
      } else {
        selectedItems.value.push(item); // Ajouter à la sélection
      }
    } else {
      selectedItems.value = [item]; // Sélection unique si CTRL/META non enfoncé
    }
    
    
  };

  const isSelected = (item) => {
    emit('returnSelectedData',selectedItems)
    return selectedItems.value.some(selectedItem => selectedItem.id === item.id);
  };
   
    // Calculer les données paginées
    const paginatedData = computed(() => {
      const start = (currentPage.value - 1) * itemsPerPage.value;
      const end = start + itemsPerPage.value;
      return filteredData.value.slice(start, end);
    });

    // Nombre total de pages
    const totalPages = computed(() => {
      return Math.ceil(filteredData.value.length / itemsPerPage.value);
    });

    // Aller à la page précédente
    const previousPage = () => {
      if (currentPage.value > 1) {
        currentPage.value--;
      }
    };

    // Aller à la page suivante
    const nextPage = () => {
      if (currentPage.value < totalPages.value) {
        currentPage.value++;
      }
    };

   const countItem = (index) => (index + 1 + (currentPage.value - 1) * itemsPerPage.value)


  //  const  formatNumber = (number) => number.toString().replace(/\B(?=(\d{3})+(?!\d))/g, " ");

</script>

<style scoped>
tr.selected {
background-color: #f2f2f2;
}
a,td{
  cursor: pointer;
}
.bg-hp-orange{
  background-color: #FBB03B !important;
  color:#fff
}
.bg-hp-green{
  background-color: #0D7D62 !important;
  color:#fff
}
.cp-table{
  border: 1px solid #d2d0d0b3;
  border-radius: 5px;
  padding: 3px;
}
.table{
  overflow-x: auto !important;
}
</style>
