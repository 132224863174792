<template>
    <div class="modal fade" id="modal-delete">
        <div class="modal-dialog modal-md">
            <div class="modal-content">
                <div class="modal-header">
                    <h4 class="modal-title">CONFIRMATION DE SUPPRESSION</h4>
                    <button type="button" class="close" @click="handleClose" aria-label="Close">
                        <span aria-hidden="true">&times;</span>
                    </button>
                </div>
                <div class="modal-body" v-if="items">
                    <p class="text-center">Voulez-vous supprimer  {{ items.title  }}  </p>
                    <h4 class="text-center">{{ items.name  }} ?</h4>
                </div>
                <div class="modal-footer justify-content-between">
                    <button type="button" class="btn btn-default" @click="handleClose">Non, ne pas supprimer </button>
                    <button type="button" class="btn btn-danger W-50" :disabled="loading" @click="deletedItem">
                        <div class="spinner-border spinner-border-sm" role="status" v-if="loading" ></div>
                        Oui, supprimer  
                    </button>
                </div>
            </div>

        </div>
</div>
</template>

<script setup>
import { Modal } from "bootstrap/dist/js/bootstrap.bundle";
import { ref } from "vue";
import useHttp from "@/api"
import notify from "@/composition/notify"

    const props = defineProps({
        items: {
            type: Object,
            required: false,
            },
    });

    const loading = ref(false)

    const deletedItem = async()=>{
        loading.value = true
        try {
            const res = await useHttp.delete(`${props.items.url}/${props.items.id}`)
            if(res.status===201){
                notify.isOk(res.data.data)
                
                handleClose()

                document.querySelector('.refreshTable').click();
            }
        } catch (error) {
            console.log(error);
            
        }
        loading.value = false
    }

    const handleClose = () => {
    const modalElement = document.getElementById("modal-delete");
    
    // Si une instance du modal existe déjà
        const modal = Modal.getInstance(modalElement); // Récupérer l'instance du modal existante
      
        if (modal) {
            modal.hide(); // Ferme le modal
        }
    }
</script>

<style lang="scss" scoped>

</style>