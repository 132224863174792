<template>
   <DefaultView>
      <div class="row justify-content-end mr-1">
        <button type="button" class="btn btn-sm btn-success" data-toggle="modal" data-target="#modal-company">
            Nouveau <i class="nav-icon fas fa-plus"></i>
        </button>
      </div>
      <template v-if="loading">
          <DataLoader />
      </template>
      <template v-else>
          <template v-if="errors">
            <ServerDisconnect :but="handleGetCompany" />          
          </template>
          <template v-else>
              <DataTableView :data="data" />
            </template>
      </template>
      <CompanyModal @update="saveOur"/>
    </DefaultView>
</template>

<script setup>
    import { onMounted } from 'vue'
    import DefaultView from '@/layouts/DefaultView.vue';
    import useCompany from '../../composition/useCompany';
    import DataLoader from '@/components/DataLoader.vue';
    import ServerDisconnect from '@/components/ServerDisconnect.vue';
    import DataTableView from '@/components/datatables/company/DataTableView.vue'
    import CompanyModal from '@/components/modals/CompanyModal.vue'
    
    const {handleGetCompany, loading,data,errors} = useCompany()

    const saveOur = () =>{
      // if(msg){
        handleGetCompany()
      // }
    }

    onMounted(()=>{
      handleGetCompany()
    })
</script>

<style lang="scss" scoped>

</style>
