import useHttp from "@/api"
import { ref } from "vue"
import notify from "./notify"
import { useAuthStore } from "@/store"
export default function useProduct(){

    const store = useAuthStore()
    const loading = ref(false)
    const stock = ref([]);
    const product = ref([]);

    const form = ref({
        id: 0,
        produit :null,
        entreprise :null,
        utilisateur :null,
        quantite :null,
        stock_critique :null,
        stock_alerte :null
    })

    const serverError = ref(false)

    const handleSave = async() =>{
        loading.value = true
        if(form.value.id > 0){
            try {
                const resp = await useHttp.put(`stock/${form.value.id}`,form.value)
                
                if(resp.status === 201){
                    notify.isOk(resp.data.data)
                    document.getElementById('close').click()
                }
                   
                
            } catch (error) {
                console.log(error);
                serverError.value = true
            }
        }else{
            try {
                form.value.entreprise = store.user.entreprise_id
                form.value.utilisateur = store.user.id
                const resp = await useHttp.post('stock',form.value)
                
                if(resp.status === 201){
                    notify.isOk(resp.data.data)
                    document.getElementById('close').click()
                }
                   
                
            } catch (error) {
                console.log(error);
                serverError.value = true
            }
        }
        loading.value = false

        document.querySelector(".refreshTable").click();
    }

    const getStock = async () =>{
        loading.value = true
        try {
            const res = await useHttp.get('stock');

            stock.value = await res.data.data;
            console.log('stock',stock.value);
            console.log('stock',res.data.data);
            
        } catch (error) {
            console.log(error);
            
        }
        loading.value = false
    }
    const getProduct = async () =>{
        loading.value = true
        try {
            const res = await useHttp.get('product');

            product.value = await res.data.data;
           console.log(product.value);
           
            
        } catch (error) {
            console.log(error);
            
        }
        loading.value = false
    }


    return {
        form,
        loading,
        handleSave,
        stock,
        getStock,
        serverError,
        product,
        getProduct
    }
}