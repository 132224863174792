import { createRouter, createWebHistory } from 'vue-router'
import routes from './url'
import { useAuthStore } from '@/store'
const router = createRouter({
  history: createWebHistory(process.env.BASE_URL),
  routes
})


router.beforeEach(async (to) => {
  const store = useAuthStore();

  if (
    // make sure the user is authenticated
    !store.isAthenticated && store.token === null &&
    // ❗️ Avoid an infinite redirect
    to.name !== 'login'
  ) {
    // redirect the user to the login page
    return { name: 'login' }
  }
})

export default router
