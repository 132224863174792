<template>
    <DefaultView>
        <div class="row justify-content-end mr-1">
            <button type="button" class="btn btn-sm btn-success mb-2" data-toggle="modal" data-target="#modal-stock">
                Nouveau <i class="nav-icon fas fa-plus"></i>
            </button>
        </div>
        <StockTableView :data="stock" />
        <StockModal />
    </DefaultView>
</template>

<script setup>
    import DefaultView from "@/layouts/DefaultView.vue"
    import StockTableView from "@/components/datatables/stock/StockTableView.vue";
    import StockModal from "@/components/modals/StockModal.vue";
    import useStock from "@/composition/useStock";
    import { onMounted } from "vue";

    const {getStock, stock }= useStock()

    // const data=[
    //     {id: 1, el1: 'Element 1'},
    //     {id: 2, el1: 'Element 2'},
    //     {id: 3, el1: 'Element 3'},
    // ]

    onMounted(()=>{
        getStock()
    })
</script>

<style lang="scss" scoped>

</style>