<template>
  <div class="bg-white cp-table">

      <div class="row d-flex justify-content-between mb-1">
          <div class="col-6">
              <p class="ml-3 mt-2"><i class="nav-icon fas fa-database text-success"></i> {{ props.data.length }} Commande(s) de shopify</p>
          </div>
          <div class="col-2 mt-2">
            <input type="date" class="form-control" v-model="dateSearch">
          </div>
          <div class="col-3 mt-2">
               <!-- Zone de recherche -->
              <input v-model="searchQuery" placeholder="Rechercher..." class="form-control" />
          </div>
      </div>
    <!-- Tableau des données -->
      <div class="card-body table-responsive p-0" style="height: 58vh; overflow-x: auto;">
          <table class="table text-nowrap">
              <thead class="bg-hp-green sticky-top top-0">
                  <tr class="bg-hp-green">
                      <th>#</th>
                      <th>Code cmde</th>
                      <th>Client</th>
                      <th>Téléphone</th>
                      <th>Pays</th>
                      <th>Ville</th>
                      <th>Lieu de livraison</th>
                      <th>Désignation</th>
                      <th>statut</th>
                      <th>Créer le</th>
                      <th>Qte</th>
                      <th>P.U</th>
                      <th>Total Achat</th>
                      <th>Net à payer</th>
                  </tr>
              </thead>
              <tbody>
                  <template v-if="paginatedData.length">
                      <tr v-for="(item, index) in paginatedData" :key="index" @click="selectRow(item, $event)"
                      :class="{ selected: isSelected(item) }">
                          <td>{{ countItem(index) }}</td>
                          <td>{{ item.codeCommande }}</td>
                          <td>{{ item.client }}</td>
                          <td>{{ item.telephone }}</td>
                          <td>{{ item.pays }}</td>
                          <td>{{ item.ville }}</td>
                          <td>{{ item.adresseLivraison }}</td>
                          <td>{{ item.designation }}</td>
                          <td><span :class="`badge badge-${stateColor(item.state)}`">{{ item.statut }}</span></td>
                          <td>{{ item.dateCreation }}</td>
                          <td>{{ item.quantite }}</td>
                          <td>{{ formatNumber(item.pu) }} FCFA</td>
                          <td>{{ formatNumber(item.achatTotal) }} FCFA</td>
                          <td>{{ formatNumber(item.netPayer) }} FCFA</td>
                      </tr>
                  </template>
                  <template v-else>
                  <tr>
                      <td colspan="13" class="text-center"><span class="text-center mt-5"><i class="nav-icon fas fa-exclamation-triangle text-warning mr-3"></i>Aucune information trouvée !</span></td>
                  </tr>
                      
                  </template>
              </tbody>
          </table>
      </div>
    <!-- Pagination -->
      <div class="d-flex flex-row-reverse">
          <nav aria-label="Page navigation example">
              <ul class="pagination justify-content-end mt-2">
          
                  <li :class="`page-item ${currentPage === 1 ? 'disabled' : ''}`" >
                      <a class="page-link" @click="previousPage" >Précédent</a>
                  </li>
                  <li class="page-item">
                      <a class="page-link" href="#">{{ currentPage }}</a>
                  </li>
                  <li class="page-item">
                      <a class="page-link" href="#">/ </a>
                  </li>
                  <li class="page-item">
                      <a class="page-link" href="#">{{ totalPages }}</a>
                  </li>
                  <li :class="`page-item ${currentPage === totalPages ? 'disabled' : ''}`">
                      <a class="page-link" @click="nextPage" >Suivant</a>
                  </li>
              </ul>
          
          </nav>
          <ul class="pagination justify-content-end mt-2 mr-2"> 
          <p class="text-left mt-2 mr-2"> Afficher par nombre de </p>
              <select v-model="itemsPerPage" class="form-control" style="width: 5rem;">
                  <option :value="10">10 </option>
                  <option :value="15">15 </option>
                  <option :value="25">25 </option>
                  <option :value="50">50 </option>
                  <option :value="100">100 </option>
                  <option :value="150">150 </option>
              </select>
          </ul>
  
      </div>
  </div>
</template>

<script setup>
  import { ref, computed } from 'vue';


      
    // Simule des données
  const props = defineProps({
    data:{
      type: Array,
      required:true
    }
  })

    // Données réactives pour la recherche et la pagination
    const searchQuery = ref('');
    const currentPage = ref(1);
    const itemsPerPage = ref(10);
    const dateSearch = ref();


    // Filtrer les données en fonction de la recherche
    const filteredData = computed(() => {
      let items = props.data

      if (dateSearch.value){
        const mydate = new Date(dateSearch.value).toISOString().split('T')[0];
          items = items.filter(item => {
              const itemDate = new Date(item.filterDate).toISOString().split('T')[0];
        return itemDate === mydate;
    });
        }

      if (searchQuery.value) {
        items = props.data.filter((item) =>
           item.codeCommande != null && item.codeCommande.toLowerCase().includes(searchQuery.value.toLowerCase()) ||
           item.client != null && item.client.toLowerCase().includes(searchQuery.value.toLowerCase())||
           item.telephone != null && item.telephone.toLowerCase().includes(searchQuery.value.toLowerCase())||
           item.pays != null && item.pays.toLowerCase().includes(searchQuery.value.toLowerCase())||
           item.ville != null && item.ville.toLowerCase().includes(searchQuery.value.toLowerCase())||
           item.statut != null && item.statut.toLowerCase().includes(searchQuery.value.toLowerCase())||
           item.dateCreation != null && item.dateCreation.toLowerCase().includes(searchQuery.value.toLowerCase())||
           item.quantite != null && item.quantite.toLowerCase().includes(searchQuery.value.toLowerCase())||
           item.pu != null && item.pu.toLowerCase().includes(searchQuery.value.toLowerCase())||
           item.achatTotal != null && item.achatTotal.toLowerCase().includes(searchQuery.value.toLowerCase())||
           item.netPayer != null && item.netPayer.toLowerCase().includes(searchQuery.value.toLowerCase())
          
        );
      }
      if((searchQuery.value) && (dateSearch.value)){
        items = props.data.filter((item) =>
           item.codeCommande != null && item.codeCommande.toLowerCase().includes(searchQuery.value.toLowerCase()) ||
           item.client != null && item.client.toLowerCase().includes(searchQuery.value.toLowerCase())||
           item.telephone != null && item.telephone.toLowerCase().includes(searchQuery.value.toLowerCase())||
           item.pays != null && item.pays.toLowerCase().includes(searchQuery.value.toLowerCase())||
           item.ville != null && item.ville.toLowerCase().includes(searchQuery.value.toLowerCase())||
           item.statut != null && item.statut.toLowerCase().includes(searchQuery.value.toLowerCase())||
           item.dateCreation != null && item.dateCreation.toLowerCase().includes(searchQuery.value.toLowerCase())||
           item.quantite != null && item.quantite.toLowerCase().includes(searchQuery.value.toLowerCase())||
           item.pu != null && item.pu.toLowerCase().includes(searchQuery.value.toLowerCase())||
           item.achatTotal != null && item.achatTotal.toLowerCase().includes(searchQuery.value.toLowerCase())||
           item.netPayer != null && item.netPayer.toLowerCase().includes(searchQuery.value.toLowerCase()));
        
           const mydate = new Date(dateSearch.value).toISOString().split('T')[0];
          items = items.filter(item => {
              const itemDate = new Date(item.filterDate).toISOString().split('T')[0];
        return itemDate === mydate;
    });
      }
      return items;
    });
    
    const stateColor = (state) => {
      switch(parseInt(state)) {
        case 1: return 'info text-white'
        case 2: return 'warning text-white'
        case 3: return 'primary text-white'
        case 4: return 'success text-white'
        case 5: return 'danger text-white'
      }
    }

    const emit = defineEmits(['returnSelectedData'])

    const selectedItems = ref([]);

    const selectRow = (item, event) => {
    if (event.ctrlKey || event.metaKey) {  // Pour macOS, utilisez également `event.metaKey`
      const index = selectedItems.value.findIndex(selectedItem => selectedItem.id === item.id);
      if (index !== -1) {
        selectedItems.value.splice(index, 1); // Déselectionner si déjà sélectionné
      } else {
        selectedItems.value.push(item); // Ajouter à la sélection
      }
    } else {
      selectedItems.value = [item]; // Sélection unique si CTRL/META non enfoncé
    }
    
    
  };

  const isSelected = (item) => {
    emit('returnSelectedData',selectedItems)
    return selectedItems.value.some(selectedItem => selectedItem.id === item.id);
  };
   
    // Calculer les données paginées
    const paginatedData = computed(() => {
      const start = (currentPage.value - 1) * itemsPerPage.value;
      const end = start + itemsPerPage.value;
      return filteredData.value.slice(start, end);
    });

    // Nombre total de pages
    const totalPages = computed(() => {
      return Math.ceil(filteredData.value.length / itemsPerPage.value);
    });

    // Aller à la page précédente
    const previousPage = () => {
      if (currentPage.value > 1) {
        currentPage.value--;
      }
    };

    // Aller à la page suivante
    const nextPage = () => {
      if (currentPage.value < totalPages.value) {
        currentPage.value++;
      }
    };

   const countItem = (index) => (index + 1 + (currentPage.value - 1) * itemsPerPage.value)


   const  formatNumber = (number) => number.toString().replace(/\B(?=(\d{3})+(?!\d))/g, " ");

</script>

<style scoped>
tr.selected {
background-color: #f2f2f2;
}
a,td{
  cursor: pointer;
}
.bg-hp-orange{
  background-color: #FBB03B !important;
  color:#fff
}
.bg-hp-green{
  background-color: #0D7D62 !important;
  color:#fff
}
.cp-table{
  border: 1px solid #d2d0d0b3;
  border-radius: 5px;
  padding: 3px;
}
.table{
  overflow-x: auto !important;
}
</style>
