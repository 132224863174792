<template>
<div class="wrapper">

<HeadBar />
<AsideBar />

<div class="content-wrapper">

    <div class="content-header">
        <div class="container-fluid">
            <div class="row mb-2">
                <div class="col-sm-6">
                    <h1 class="m-0">{{ currentPath }}</h1>
                </div>
                <div class="col-sm-6">
                    <ol class="breadcrumb float-sm-right">
                        <li class="breadcrumb-item"><router-link :to="{path : lastPath}">{{ backPath }}</router-link></li>
                        <li class="breadcrumb-item active">{{ currentPath }}</li>
                    </ol>
                </div>
            </div>
        </div>
    </div>


    <div class="content">
        <div class="container-fluid">
            
            <slot name="default"></slot>
        </div>
    </div>

</div>

    <FooterBar />

</div>

</template>

<script setup>

import { onMounted, ref } from 'vue';
import AsideBar from './AsideBar.vue';
import FooterBar from './FooterBar.vue';
import HeadBar from './HeadBar.vue';
import { useRouter } from 'vue-router';
import {useAuthStore} from '@/store'

    const lastPath = ref(null)
    const router = useRouter()
    const backPath = ref(null)
    const currentPath = ref(null)
    onMounted( () =>{
        const store = useAuthStore()
        if(store.user == null){
            router.push({name:'login'})
        }
        lastPath.value = router.options.history.state.back
        let back = router.options.history.state.back
        let current = router.options.history.state.current
        
        
        back ===null ? back = '' :  backPath.value = ucfirst(back.slice(1))
        current ===null ? current = '' :  currentPath.value = ucfirst(current.slice(1))
        
    })

    const ucfirst = (string) =>{
        return string.charAt(0).toUpperCase() + string.slice(1);
    }
</script>

<style lang="css" scoped>

</style>