<template>
    <div class="modal fade" id="modal-costumer">
        <div class="modal-dialog modal-lg">
            <div class="modal-content">
                <div class="modal-header">
                    <h4 class="modal-title">Client</h4>
                    <button type="button" class="close" data-dismiss="modal" aria-label="Close" @click="handleClose">
                        <span aria-hidden="true">&times;</span>
                    </button>
                </div>
                <form action="#">
                    <div class="modal-body">
                    <div class="row">
                        <div class="col-6">
                            <div class="form-group">
                                <label for="nom">Nom</label>
                                <input type="text" v-model="form.nom_client" class="form-control" id="nom" placeholder="Nom" style="text-transform: uppercase;">
                                <!-- <input type="number" v-model="form.entreprise" class="form-control" id="ent" placeholder="E" > -->
                            </div>
                        </div>
                        <div class="col-6">
                            <div class="form-group">
                                <label for="prenoms">Prénoms</label>
                                <input type="text" v-model="form.prenom_client" class="form-control" id="prenoms" placeholder="Prénoms" style="text-transform: capitalize;">
                                
                            </div>
                        </div>
                        
                    </div>
                    <div class="row">
                        <div class="col-6">
                            <div class="form-group">
                                <label for="em">Adresse email</label>
                                <input type="email" v-model="form.adresse_email" class="form-control dateHp" id="em" placeholder="Adresse email">
                            </div>
                        </div>
                        <div class="col-3">
                            <div class="form-group">
                                <label for="rue">Adresse de la rue</label>
                                <input type="text" v-model="form.adresse_rue" class="form-control" id="rue" placeholder="Adresse de la rue">
                                
                            </div>
                        </div>
                        <div class="col-3">
                            <div class="form-group">
                                <label for="sexe">Sexe</label>
                                <select v-model="form.sexe"  class="form-control" id="sexe">
                                    <option value="Homme">Homme</option>
                                    <option value="Femme">Femme</option>
                                </select>
                            </div>
                        </div>
                        
                    </div>
                    <div class="row">
                        <div class="col-6">
                            <div class="form-group">
                                <label for="telephone1">Téléphone</label>
                                <input type="text" v-model="form.telephone_client" class="form-control" id="telephone1" placeholder="Téléphone">
                            </div>
                        </div>
                        <div class="col-6">
                            <div class="form-group">
                                <label for="telephone2">Téléphone 2</label>
                                <input type="text" v-model="form.telephone_2_client" class="form-control" id="telephone2" placeholder="Téléphone 2">
                            </div>
                        </div>
                        
                    </div>
                    <div class="row">
                        <div class="col-6">
                            <div class="form-group">
                                <label for="pays">Pays</label>
                                <input type="text" v-model="form.pays" class="form-control" id="pays" placeholder="Pays">
                            </div>
                        </div>
                        <div class="col-6">
                            <div class="form-group">
                                <label for="ville">Ville</label>
                                <input type="text" v-model="form.ville" class="form-control" id="ville" placeholder="Ville">
                                <!-- <SelectVieW :options="dataCompany" @return-id="handleGetId" /> -->
                            </div>
                        </div>
                        
                    </div>
                   
                </div>
                <div class="modal-footer justify-content-between">
                    <button type="button" class="btn btn-default" data-dismiss="modal" aria-label="Close" @click="handleClose">fermer</button>
                    <button type="button" class="btn btn-success" @click.prevent="handleSave" :disabled="loading">
                        <div class="spinner-border spinner-border-sm" role="status" v-if="loading" ></div>
                        Enregistrer <i class="fas fa-save nav-icon"></i>
                    </button>
                </div>
                </form>
            </div>
        </div>
    </div>       
</template>


<script setup>
import { ref, watch } from 'vue';
import useCostumer from '../../composition/useCostumer';
import { Modal } from "bootstrap/dist/js/bootstrap.bundle";
import { useAuthStore } from '@/store';


    const { form, handleSave, loading } = useCostumer()


    const props = defineProps({
        data:{
            type:Object,
            require:false
        }
    })


    form.value = { ...props.data };
    watch(() => props.data, (newData) => {
        form.value = { ...newData };
        
    });


    const emit = defineEmits(['update'])

    const store = useAuthStore()

    const formData = ref({
        id: 0,
        nom_client : null,
        prenom_client : null,
        sexe : null,
        telephone_client : null,
        telephone_2_client : null,
        pays : null,
        ville : null,
        adresse_rue : null,
        adresse_email : null,
        entreprise : store.user.entreprise_id
    })

    const handleClose = () => {
    const modalElement = document.getElementById("modal-costumer");
    form.value = {...formData.value};
    // Si une instance du modal existe déjà
        const modal = Modal.getInstance(modalElement); // Récupérer l'instance du modal existante
        emit('update',false)
        if (modal) {
            modal.hide(); // Ferme le modal
        }
    }
</script>