<template>
    <div class="modal fade" id="modal-Delivery">
        <div class="modal-dialog modal-lg">
            <div class="modal-content">
                <div class="modal-header">
                    <h4 class="modal-title">Livreur</h4>
                    <button type="button" class="close" data-dismiss="modal" aria-label="Close">
                        <span aria-hidden="true">&times;</span>
                    </button>
                </div>
                <form action="#">
                    <div class="modal-body">
                    <div class="row">
                        <div class="col-6">
                            <div class="form-group">
                                <label for="nom">Nom</label>
                                <input type="text" v-model="form.nom" class="form-control" id="nom" placeholder="Nom" style="text-transform: uppercase;">
                            </div>
                        </div>
                        <div class="col-6">
                            <div class="form-group">
                                <label for="prenoms">Prénoms</label>
                                <input type="text" v-model="form.prenoms" class="form-control" id="prenoms" placeholder="Prénoms" style="text-transform: capitalize;">
                                
                            </div>
                        </div>
                        
                    </div>
                    <div class="row">
                        <div class="col-6">
                            <div class="form-group">
                                <label for="dn">Date de naissance</label>
                                <input type="date" v-model="form.date_de_naissance" class="form-control dateHp" id="dn" placeholder="Date de naissance">
                            </div>
                        </div>
                        <div class="col-6">
                            <div class="form-group">
                                <label for="code">Sexe</label>
                                <select v-model="form.sexe"  class="form-control">
                                    <option value="Homme">Homme</option>
                                    <option value="Femme">Femme</option>
                                </select>
                            </div>
                        </div>
                        
                    </div>
                    <div class="row">
                        <div class="col-6">
                            <div class="form-group">
                                <label for="telephone1">Téléphone</label>
                                <input type="text" v-model="form.telephone1" class="form-control" id="telephone1" placeholder="Téléphone">
                            </div>
                        </div>
                        <div class="col-6">
                            <div class="form-group">
                                <label for="telephone2">Téléphone2</label>
                                <input type="text" v-model="form.telephone2" class="form-control" id="telephone2" placeholder="telephone 2">
                            </div>
                        </div>
                        
                    </div>
                    <div class="row">
                        <div class="col-6">
                            <div class="form-group">
                                <label for="ts">Date d'embauche</label>
                                <input type="date" v-model="form.date_embauche" class="form-control" id="ts" placeholder="Date d'embauche">
                            </div>
                        </div>
                        <div class="col-6">
                            <div class="form-group">
                                <label for="ae">Entreprise</label>
                                <SelectVieW :options="dataCompany" @return-id="handleGetId" />
                            </div>
                        </div>
                        
                    </div>
                   
                </div>
                <div class="modal-footer justify-content-between">
                    <button type="button" class="btn btn-default" data-dismiss="modal" id="close">fermer</button>
                    <button type="button" class="btn btn-success" @click.prevent="handleSave" :disabled="loading">
                        <div class="spinner-border spinner-border-sm" role="status" v-if="loading" ></div>
                        Enregistrer
                    </button>
                </div>
                </form>
            </div>
        </div>
    </div>       
</template>

<script setup>
    import useDelivery from '@/composition/useDelivery'
    import { onMounted} from 'vue';
    import SelectVieW from '@/components/select/SelectCompanyView.vue'
    const { handleSave,loading, form, dataCompany, handleCompany } = useDelivery()

    const handleGetId = (id) =>{
        form.value.entreprise = id
    }
   
    onMounted( () =>{
        handleCompany()

    })

</script>