import { ref } from "vue";
import useHttp from "../api";
import notify from "./notify"
export default function useShopify(){
    const data = ref([])
    const loading = ref(false)
    const loadingaff = ref(false)
    const errors = ref()
    const deliveryData = ref([])

    const getShopifyData = async() =>{
        loading.value = true
        try {
            await useHttp.get('csrf-cookie');
            const result = await useHttp.get('shopify');
                    
            data.value = result.data.data
            
        } catch (error) {
            errors.value =error.code
        }
        loading.value = false
    }

    const getDelivery = async () =>{
        try {
            const res = await useHttp.get('delivery');
            
            deliveryData.value = res.data.data
            
        } catch (error) {
            console.log(error);
            
        }
    }

    const handlesaveShopify = async(data) =>{
        loadingaff.value = true
        try {
            const response = await useHttp.post('order',data)
            notify.isOk(response.data.data)
            
        } catch (error) {
            notify.isNotOk(error.data.data)
        }
        
        loadingaff.value = false
    }


    return {
        data,
        loading,
        errors,
        loadingaff,
        deliveryData,
        getShopifyData,
        getDelivery,
        handlesaveShopify
    }
}