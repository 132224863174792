<template>
    <div class="dropdown-wrapper" >
        <div class="selected-item" @click="handleVisible">
            <span>{{title}} </span>
            <i :class="`fas fa-angle-${isVisible? 'up' : 'down'} right`"></i>
        </div>
        <div class="dropdown-popover" v-if="isVisible">
            <input type="text" v-model="searchQuery" placeholder="Rechercher..." class="search-input">
            <div class="options">
                <ul>
                <template v-if="filterData.length">
                    <li v-for="(item,index) in filterData" :key="index" @click="selectedItem(item)">{{ item.rs }} {{ item.pays }}</li>
                </template>
                <template v-else>
                    <li><strong>{{ searchQuery }} </strong>, n'a pas été trouvé !</li>
                </template>
                    
                </ul>
            </div>
        </div>
    </div>
</template>

<script setup>
import { computed, ref } from 'vue';

    const isVisible =ref(false)
    const searchQuery =ref(null)
    const title = ref('Sélectioner une entreprise')

    const handleVisible = ()=>{
    isVisible.value = !isVisible.value
  }


  const filterData = computed(() =>{
    if(searchQuery.value){
        return props.options.filter((item) => item.rs.toLowerCase().includes(searchQuery.value.toLowerCase())||
         item.pays.toLowerCase().includes(searchQuery.value.toLowerCase()))
    }
    return props.options
  })
  const emit =defineEmits(['returnId'])
  const selectedItem = (data) =>{
    emit('returnId',data.id);
    title.value =`${data.rs} ${data.pays}`
    isVisible.value = !isVisible.value
  }
  const props = defineProps({
    options:{
            type : Array,
            required : true
        }
    })
</script>

<style lang="css" scoped>
    .dropdown-wrapper{
        max-width: 380px;
        position: relative;
        margin: 0 auto;
    }
    .dropdown-wrapper .selected-item{
        height: 40px;
        border: 1px solid lightgray;
        border-radius: 5px;
        padding: 5px 10px;
        display: flex;
        justify-content: space-between;
        align-items: center;
        font-size:20px;
        font-weight: 300;
    }
    .dropdown-wrapper .dropdown-popover{
        position: absolute;
        border: 1px solid lightgray;
        top: 37px;
        left: 0;
        right: 0;
        background-color: #fff;
        max-width: 100%;
        padding: 2px;
        border-bottom-right-radius: 3px;
        border-bottom-left-radius: 3px;
    }

    .search-input{
        width: 100%;
        height: 40px;
        border: 1px solid lightgray;
        border-radius: 5px;
        font-size: 18px;
        padding: 15px;
    }
    .search-input:focus{
        outline: none;
        border: 1px solid rgb(37, 121, 189);
        border-radius: 5px;
    }
    .options{
        width: 100%;
        padding: 5px;
    }

    .options ul{
        list-style: none;
        text-align: left;
        padding-left: 2px;
        max-height: 200px;
        overflow-y: scroll;
        overflow-x: hidden;
    }
    .options ul li{
        border-bottom: 2px solid #fff;
        padding: 10px;
        background-color: rgba(196, 194, 194, 0.648);
        cursor: pointer;
        border-radius: 3px;
    }
    .options ul li:hover{
        background-color: rgba(5, 176, 250, 0.482);
        color: #fff;
    }
    .options ul li:last-child{
        padding-bottom: 5px;
    }
</style>