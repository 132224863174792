<template>
    <footer class="main-footer">

        <div class="float-right d-none d-sm-inline">
            HP-ERP
         </div>

        <strong>Copyright &copy; 2014-2021 <a href="#">Harmopharm</a>.</strong> Tout droit reservé.
    </footer>
</template>

<script setup>

</script>

<style lang="css" scoped>

</style>