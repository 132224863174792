<template>
  <h2 class="text-center text-black mt-5">Chargement des données en cours !</h2>
    <div class="d-flex align-items-center justify-content-center" id="loader">
        <span class="loader"></span>
    </div>
   
</template>

<style scoped>
    .loader {
          position: relative;
          display: flex;
        }
        .loader:before , .loader:after {
            content: '';
            width: 20px;
            height: 20px;
            display: inline-block;
            position: relative;
            margin: 0  5px  ;
            border-radius: 50%;
            color: #FBB03B;
            background: currentColor;
            box-shadow: 50px 0 ,  -50px 0;
            animation: left 1s infinite ease-in-out;
          }
          .loader:after {
            color: #0D7D62;
            animation: right 1.1s infinite ease-in-out;
          }


        @keyframes right {
          0% , 100%{transform: translateY(-20px) }
          50% { transform: translateY(20px) }
        }

        @keyframes left {
          0% , 100%{ transform: translateY(20px) }
          50% { transform: translateY(-20px) }
        }
        #loader{
            background: rgba(255, 255, 255, 0.1);
            height: 68vh;
        }
</style>