<template>
    <aside class="main-sidebar sidebar-dark-primary elevation-4">

            <a href="index3.html" class="brand-link">
                <img src="@/assets/img/logo.png" alt="Logo harmopharm" class="brand-image img-responsive"> <br>
            </a>

            <div class="sidebar">

                <div class="user-panel mt-3 pb-3 mb-3 d-flex">
                    <div class="image">
                        <img src="dist/img/user2-160x160.jpg" class="img-circle elevation-2" alt="User Image">
                    </div>
                    <div class="info">
                        <a href="#" class="d-block" v-if="store.user">{{store.user.name}} {{store.user.lastname}}</a>
                    </div>
                </div>

                <div class="form-inline">
                    <div class="input-group" data-widget="sidebar-search">
                        <input class="form-control form-control-sidebar" type="search" placeholder="Rechercher de menu" aria-label="Search">
                        <div class="input-group-append">
                            <button class="btn btn-sidebar">
                                <i class="fas fa-search fa-fw"></i>
                            </button>
                        </div>
                    </div>
                </div>

                <nav class="mt-2">
                    <ul class="nav nav-pills nav-sidebar flex-column" data-widget="treeview" role="menu" data-accordion="false">
                        <li class="nav-item">
                            <router-link :to="{name: 'home'}" class="nav-link">
                                <i class="nav-icon fas fa-tachometer-alt"></i>
                                <p>
                                    Tableau de bord
                                </p>
                            </router-link>
                        </li>
                        <li class="nav-item">
                            <router-link :to="{name: 'company'}" class="nav-link">
                                <i class="nav-icon fas fa-building"></i>
                                <p>
                                    Entreprise
                                </p>
                            </router-link>
                        </li>
                        <li class="nav-item">
                            <router-link :to="{name: 'employe'}" class="nav-link">
                                <i class="nav-icon fas fa-users"></i>
                                <p>
                                    Employé
                                </p>
                            </router-link>
                        </li>
                        <li class="nav-item">
                            <router-link :to="{name: 'delivery'}" class="nav-link">
                                <i class="nav-icon fas fa-biking"></i>
                                <p>
                                    Livreur
                                </p>
                            </router-link>
                        </li>
                        <li class="nav-item">
                            <router-link :to="{name : 'costumer'}" class="nav-link">
                                <i class="nav-icon fas fa-users"></i>
                                <p>
                                    Client
                                </p>
                            </router-link>
                        </li>

                        <li class="nav-item">
                                    <router-link :to="{ name : 'product' }" class="nav-link">
                                        <i class="nav-icon fas fa-tablets"></i>
                                        <p>Produit</p>
                                    </router-link>
                                </li>
                                <li class="nav-item">
                                    <router-link :to="{ name : 'stock' }" class="nav-link">
                                        <i class="nav-icon fas fa-th-large"></i>
                                        <p>Stock</p>
                                    </router-link>
                                </li>
                                <li class="nav-item">
                                    <router-link :to="{name:'mlmorder'}" class="nav-link">
                                        <i class="fas fa-mortar-pestle nav-icon"></i>
                                        <p>MLM</p>
                                    </router-link>
                                </li>
                                <li class="nav-item">
                                    <router-link :to="{name:'shopify'}" class="nav-link">
                                        <i class="nav-icon fab fa-shopify"></i>
                                        <p>
                                            Shopify
                                        </p>
                                    </router-link>
                                </li>
                        
                        
                    </ul>
                </nav>

            </div>

    </aside>
</template>

<script setup>
import { useAuthStore } from '@/store';


const store = useAuthStore()

</script>

<style lang="scss" scoped>

</style>