<template>
    <div class="modal fade" id="modal-company">
        <div class="modal-dialog modal-lg">
            <div class="modal-content">
                <div class="modal-header">
                    <h4 class="modal-title">Entreprise</h4>
                    <button type="button" class="close" data-dismiss="modal" aria-label="Close" @click="handleClose">
                        <span aria-hidden="true">&times;</span>
                    </button>
                </div>
                <form action="#">
                    <div class="modal-body">
                    <div class="row">
                        <div class="col-12">
                            <div class="form-group">
                                <label for="rs">Raison sociale</label>
                                <input type="text" v-model="form.raison_sociale" class="form-control" id="rs" placeholder="Raison sociale">
                                <input type="hidden" v-model="form.id" class="form-control" id="id">
                            </div>
                        </div>
                        
                    </div>
                    <div class="row">
                        <div class="col-6">
                            <div class="form-group">
                                <label for="pays">Pays</label>
                                <input type="text" v-model="form.pays" class="form-control" id="pays" placeholder="Pays">
                            </div>
                        </div>
                        <div class="col-6">
                            <div class="form-group">
                                <label for="code">Code pays</label>
                                <input type="text" v-model="form.code" class="form-control" id="code" placeholder="exemple: Côte d'ivoire (CI)">
                            </div>
                        </div>
                        
                    </div>
                    <div class="row">
                        <div class="col-6">
                            <div class="form-group">
                                <label for="vi">Ville</label>
                                <input type="text" v-model="form.ville" class="form-control" id="vi" placeholder="Pays">
                            </div>
                        </div>
                        <div class="col-6">
                            <div class="form-group">
                                <label for="ad">Adresse</label>
                                <input type="text" v-model="form.adresse" class="form-control" id="ad" placeholder="Adresse">
                            </div>
                        </div>
                        
                    </div>
                    <div class="row">
                        <div class="col-6">
                            <div class="form-group">
                                <label for="ts">Téléphone standard</label>
                                <input type="text" v-model="form.telephone_standard" class="form-control" id="ts" placeholder="Téléphone standard">
                            </div>
                        </div>
                        <div class="col-6">
                            <div class="form-group">
                                <label for="ae">Adresse email</label>
                                <input type="text" v-model="form.email" class="form-control" id="ae" placeholder="Adresse email">
                            </div>
                        </div>
                        
                    </div>
                    <div class="row">
                        <div class="col-6">
                            <div class="form-group">
                                <label for="res">Nom responsable</label>
                                <input type="text" v-model="form.nom_responsable" class="form-control" id="res" placeholder="Nom responsable">
                            </div>
                        </div>
                        <div class="col-6">
                            <div class="form-group">
                                <label for="tr">Téléphone responsable</label>
                                <input type="text" v-model="form.telephone_responsable" class="form-control" id="tr" placeholder="Téléphone responsable">
                            </div>
                        </div>
                    </div>
                </div>
                <div class="modal-footer justify-content-between">
                    <button type="button" class="btn btn-default" data-dismiss="modal" id="close" @click="handleClose">fermer</button>
                    <button type="button" class="btn btn-success" @click.prevent="handleSave" :disabled="loading">
                        <div class="spinner-border spinner-border-sm" role="status" v-if="loading" ></div>
                        Enregistrer <i class="nav-icon fas fa-save"></i>
                    </button>
                </div>
                </form>
            </div>
        </div>
    </div>       
</template>

<script setup>
    import useCompany from '@/composition/useCompany'
    import { Modal } from "bootstrap/dist/js/bootstrap.bundle";
    import { watch, ref } from 'vue';
    const { handleSave, loading, form } = useCompany()

    const props = defineProps({
        data:{
            type:Object,
            require:false
        }
    })
    const formData = ref({
        id : 0,
        raison_sociale : null,
        code : null,
        email : null,
        telephone_standard : null,
        nom_responsable : null,
        telephone_responsable : null,
        pays : null,
        ville : null,
        adresse : null
    })
     form.value = { ...props.data }; // Copier les données reçues

    // Mettre à jour localForm si formData change
    watch(() => props.data, (newData) => {
        form.value = { ...newData };
        
    });

    const emit = defineEmits(['update'])

    emit('update',true)

    const handleClose = () => {
    const modalElement = document.getElementById("modal-company");
    form.value = {...formData.value};
    // Si une instance du modal existe déjà
        const modal = Modal.getInstance(modalElement); // Récupérer l'instance du modal existante
        emit('update')
        if (modal) {
            modal.hide(); // Ferme le modal
        }
    }
</script>