import { ref } from "vue";
import useHttp from '../api/index'
import notify  from "./notify.js";


export default function useCompany(){
    const data = ref([]);
    const loading = ref(false)
    const errors = ref('')
    
    const form = ref({
        id : 0,
        raison_sociale : null,
        code : null,
        email : null,
        telephone_standard : null,
        nom_responsable : null,
        telephone_responsable : null,
        pays : null,
        ville : null,
        adresse : null
    })

    const handleGetCompany = async() =>{
        loading.value = true
        try {
            const result = await useHttp.get('company')
            data.value = await result.data.data;    
        } catch (error) {
            errors.value =error.status
            notify.isNotOk(error.status)
            
        }
        loading.value = false
        
    }

 

    const handleSave = async()=>{
        loading.value = true 
        if((form.value.id > 0)){
            try {
                const result = await useHttp.put(`company/${form.value.id}`,form.value)
                if(result.status ===200){
                    notify.isOk(result.data.data)
                }else{
                    notify.isNotOk(result.status)
                }
            } catch (error) {
                console.log(error);
                notify.isNotOk(error.status)
            }
            
        }else{
            try {
                const result = await useHttp.post('company',form.value)
                if(result.status ===200){
                    notify.isOk(result.data.data)
                }else{
                    notify.isNotOk(result.status)
                }
            } catch (error) {
                console.log(error);
                notify.isNotOk(error.status)
            }
        }
        loading.value = false
        document.getElementById('close').click()
        
    }

    return {
        data,
        loading,
        errors,
        form,
        handleGetCompany,
        handleSave,
        // handleShow
    }
}