<template>
  <div class="bg-white cp-table">

      <div class="row d-flex justify-content-between mb-1">
          <div class="col-7">
              <p class="ml-3"><i class="nav-icon fas fa-database text-success"></i> 20 produit(s)</p>
          </div>
          <div class="col-3">
               <!-- Zone de recherche -->
              <input v-model="searchQuery" placeholder="Rechercher..." class="form-control" />
          </div>
      </div>
    <!-- Tableau des données -->
      <div class="card-body table-responsive p-0" style="height: 65vh;">
          <table class="table table-striped table-hover text-nowrap">
              <thead class="bg-hp-green sticky-top top-0">
                  <tr>
                      <th>#</th>
                      <th scope="col">Raison social</th>
                      <th scope="col">Pays</th>
                      <th scope="col">Code</th>
                      <th scope="col">Ville</th>
                      <th scope="col">Adresse</th>
                      <th scope="col">Email</th>
                      <th scope="col">Standard</th> 
                      <th scope="col">Responsable</th>
                      <th scope="col">Téléphone responsable</th>
                      <th scope="col"></th>
                  </tr>
              </thead>
              <tbody>
                  <template v-if="paginatedData.length">
                      <tr v-for="(item, index) in paginatedData" :key="index">
                          <td>{{ countItem(index) }}</td>
                              <td>{{ item.rs }}</td>
                              <td>{{ item.pays }}</td>
                              <td>{{ item.code }}</td>
                              <td>{{ item.ville }}</td>
                              <td>{{ item.adresse }}</td>
                              <td>{{ item.email }}</td>
                              <td>{{ item.tel_standard }}</td>
                              <td>{{ item.responsable }}</td>
                              <td>{{ item.telphone_resp }}</td>
                              <td>
                                  <div class="btn-group btn-group-sm" role="group" aria-label="Small button group">
                                      <button type="button" class="btn btn-outline-info"> <i class="nav-icon fas fa-eye"></i> </button>
                                      <button type="button" class="btn btn-outline-primary"><i class="nav-icon fas fa-edit"></i></button>
                                      <button type="button" class="btn btn-outline-danger"><i class="nav-icon fas fa-trash"></i></button>
                                  </div>
                              </td>
                      </tr>
                  </template>
                  <template v-else>
                  <tr>
                      <td colspan="3" class="text-center"><span class="text-center mt-5"><i class="nav-icon fas fa-exclamation-triangle text-warning mr-3"></i>Aucune information trouvée !</span></td>
                  </tr>
                      
                  </template>
              </tbody>
          </table>
      </div>
    <!-- Pagination -->
      <div class="d-flex flex-row-reverse">
          <nav aria-label="Page navigation example">
              <ul class="pagination justify-content-end">
          
                  <li :class="`page-item ${currentPage === 1 ? 'disabled' : ''}`" >
                      <a class="page-link" @click="previousPage" >Précédent</a>
                  </li>
                  <li class="page-item">
                      <a class="page-link" href="#">{{ currentPage }}</a>
                  </li>
                  <li class="page-item">
                      <a class="page-link" href="#">/ </a>
                  </li>
                  <li class="page-item">
                      <a class="page-link" href="#">{{ totalPages }}</a>
                  </li>
                  <li :class="`page-item ${currentPage === totalPages ? 'disabled' : ''}`">
                      <a class="page-link" @click="nextPage" >Suivant</a>
                  </li>
              </ul>
          
          </nav>
          <ul class="pagination justify-content-end mr-1"> 
          <p class="text-left mr-2 mt-2"> Afficher par nombre de </p>
              <select v-model="itemsPerPage" class="form-control" style="width: 5rem;">
                  <option :value="5">5 </option>
                  <option :value="15">15 </option>
                  <option :value="25">25 </option>
                  <option :value="50">50 </option>
                  <option :value="100">100 </option>
                  <option :value="150">150 </option>
              </select>
          </ul>
  
      </div>
  </div>
</template>

<script setup>
  import { ref, computed } from 'vue';

    const searchQuery = ref('');
    const currentPage = ref(1);
    const itemsPerPage = ref(5);
      
    // Simule des données
    const props = defineProps({
      data:{
        type: Array,
        required:false
      }
    })

    // Données réactives pour la recherche et la pagination
 
    // Filtrer les données en fonction de la recherche
    const filteredData = computed(() => {
      let items = props.data
      if (searchQuery.value) {
        items = props.data.value.filter((item) =>
          item.rs.toLowerCase().includes(searchQuery.value.toLowerCase()) ||
          item.pays.toLowerCase().includes(searchQuery.value.toLowerCase())
        );
      }
      return items;
    });

    // Calculer les données paginées
    const paginatedData = computed(() => {
      const start = (currentPage.value - 1) * itemsPerPage.value;
      const end = start + itemsPerPage.value;
      return filteredData.value.slice(start, end);
    });

    // Nombre total de pages
    const totalPages = computed(() => {
      return Math.ceil(filteredData.value.length / itemsPerPage.value);
    });

    // Aller à la page précédente
    const previousPage = () => {
      if (currentPage.value > 1) {
        currentPage.value--;
      }
    };

    // Aller à la page suivante
    const nextPage = () => {
      if (currentPage.value < totalPages.value) {
        currentPage.value++;
      }
    };

   const countItem = (index) => (index + 1 + (currentPage.value - 1) * itemsPerPage.value)
</script>

<style scoped>
a{
  cursor: pointer;
}
.bg-hp-orange{
  background-color: #FBB03B !important;
  color:#fff
}
.bg-hp-green{
  background-color: #0D7D62 !important;
  color:#fff
}
.cp-table{
  border: 1px solid #d2d0d0b3;
  border-radius: 5px;
  padding: 3px;
}
</style>
