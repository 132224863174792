<template>
    <DefaultView>
        <div class="row justify-content-end mr-1">
            <button type="button" class="btn btn-sm btn-primary mb-2 mr-2 refreshTable" @click="refresh">
                Actualiser &nbsp; <i class="fas fa-sync"></i>
            </button>
            <button type="button" class="btn btn-sm btn-primary mb-2" data-toggle="modal" data-target="#modal-costumer">
                Nouveau <i class="nav-icon fas fa-plus"></i>
            </button>
      </div>
      <template v-if="loading">
        <DataLoader />
      </template>
      <template v-else>
        <template v-if="servError">
            <ServerDisconnect :but="getCostumers" />
        </template>
        <template v-else>
            <CostumerTableView :data="costumers" />
        </template>
      </template>
        <CostumerModal />
    </DefaultView>
</template>

<script setup>
    import DefaultView from "@/layouts/DefaultView.vue"
    import CostumerTableView from "../../components/datatables/costumer/CostumerTableView.vue";
    import CostumerModal from "@/components/modals/CostumerModal.vue";
    import useCostumer from "@/composition/useCostumer";
    import DataLoader from "@/components/DataLoader.vue"
    import ServerDisconnect from "@/components/ServerDisconnect.vue"
    import { onMounted } from "vue";


    const { costumers, getCostumers,servError,loading } = useCostumer()

    onMounted(()=>{getCostumers()})

    const refresh = async()=> await getCostumers()
</script>

<style lang="scss" scoped>

</style>